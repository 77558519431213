import React from "react";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import { Notification as NotificationToaster, toaster } from "rsuite";
import useNotifications from "features/notifications/hooks/useNotifications";
import styled from "styled-components";
import acceptTeamInvitation from "features/teams/api/acceptTeamInvitation";
import { serverErrorHandler } from "services/server-error.service";
import denyTeamInvitation from "features/teams/api/denyTeamInvitation";
// import { ITeamInvitation } from "features/teams/types/team.interface";
import { Like } from "features/likes";
import { Notification } from "../features/notifications/types/index";
import {
  NotificationCard,
  NotificationCardToaster,
  RedDot,
} from "../features/notifications/components/NotificationCards/styled/index";

import FromNowDate from "../components/Elements/Date/FromNowDate";
import ProfileImage from "../components/Elements/ProfileImage/ProfileImage";
import { ProjectComment } from "../features/post-comments/index";
import { useAppSelector } from "../store/hooks";
import { getColorTheme } from "../store/slices/userSessionSlice";
import {
  dispatchAcceptNotification,
  dispatchAppendSocketNotification,
  dispatchDenyNotification,
  dispatchSetNotificationAlert,
} from "../store/dispatcher";
import useAppNavigation from "./useAppNavigation";
import { IUserSession } from "../types/user.interface";

const TeamNameContainer = styled.div`
  white-space: nowrap;

  p {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
`;
const DenyButton = styled.div`
  display: flex;
  border: solid thin red;
  padding-right: 10px;
  border-radius: 7px;
  align-items: center;
`;

const AcceptButton = styled.div`
  display: flex;
  border: solid thin green;
  padding-right: 10px;
  border-radius: 7px;
  align-items: center;
`;

const Container = styled.div`
  margin-top: 12px;
`;
const openProjectCommentToaster = (
  notification: Notification,
  comment: ProjectComment,
  creator: IUserSession,
  duration: number,
  nav: () => void,
  theme: any
) => {
  toaster.push(
    <NotificationToaster
      data-theme={theme}
      id="notification-toaster"
      style={{ padding: 0 }}
      type="info"
      header="New Project Comment!"
      closable
    >
      <Container
        onClick={() => {
          nav();
          toaster.clear();
        }}
      >
        <NotificationCardToaster.Container
          style={{ borderBottomColor: "transparent" }}
        >
          <NotificationCard.ContainerLeft>
            {!notification.read && <RedDot />}
            <NotificationCard.ImageContainer>
              <ProfileImage
                url={notification.creator.profileImg}
                gender={notification.creator.gender}
              />
            </NotificationCard.ImageContainer>
          </NotificationCard.ContainerLeft>

          <NotificationCard.ContainerCenter>
            <NotificationCard.TitleContainer>
              <p>
                <strong>
                  <span>{creator.username}</span> commented on a project:
                </strong>
              </p>
            </NotificationCard.TitleContainer>
            <NotificationCard.BodyContainer>
              <NotificationCard.CommentBody>
                {comment.message}
              </NotificationCard.CommentBody>
            </NotificationCard.BodyContainer>
          </NotificationCard.ContainerCenter>

          <NotificationCard.ContainerRight>
            <NotificationCard.Timestamp>
              <FromNowDate date={notification.createdOn} />
            </NotificationCard.Timestamp>
          </NotificationCard.ContainerRight>
        </NotificationCardToaster.Container>
      </Container>
    </NotificationToaster>,
    { placement: "topEnd", duration }
  );
};

const openTeamProjectCommentToaster = (
  notification: Notification,
  comment: ProjectComment,
  creator: IUserSession,
  duration: number,
  nav: () => void,
  theme: any
) => {
  toaster.push(
    <NotificationToaster
      data-theme={theme}
      id="notification-toaster"
      style={{ padding: 0 }}
      type="info"
      header="New Project Comment!"
      closable
    >
      <Container
        onClick={() => {
          nav();
          toaster.clear();
        }}
      >
        <NotificationCardToaster.Container
          style={{ borderBottomColor: "transparent" }}
        >
          <NotificationCard.ContainerLeft>
            {!notification.read && <RedDot />}
            <NotificationCard.ImageContainer>
              <ProfileImage
                url={notification.creator.profileImg}
                gender={notification.creator.gender}
              />
            </NotificationCard.ImageContainer>
          </NotificationCard.ContainerLeft>

          <NotificationCard.ContainerCenter>
            <NotificationCard.TitleContainer>
              <p>
                <strong>
                  <span>{creator.username}</span> commented on a team project:
                </strong>
              </p>
            </NotificationCard.TitleContainer>
            <NotificationCard.BodyContainer>
              <NotificationCard.CommentBody>
                {comment.message}
              </NotificationCard.CommentBody>
            </NotificationCard.BodyContainer>
          </NotificationCard.ContainerCenter>

          <NotificationCard.ContainerRight>
            <NotificationCard.Timestamp>
              <FromNowDate date={notification.createdOn} />
            </NotificationCard.Timestamp>
          </NotificationCard.ContainerRight>
        </NotificationCardToaster.Container>
      </Container>
    </NotificationToaster>,
    { placement: "topEnd", duration }
  );
};

const openTeamInviteToaster = (
  notification: Notification,
  // invitation: ITeamInvitation,
  creator: IUserSession,
  duration: number,
  nav: () => void,
  denyInvite: () => void,
  acceptInvite: () => void,
  theme: any
) => {
  toaster.push(
    <NotificationToaster
      data-theme={theme}
      id="notification-toaster"
      style={{ padding: 0 }}
      type="info"
      header="New Team Invite!"
      closable
    >
      <Container
      // onClick={() => {
      //   nav();
      //   toaster.clear();
      // }}
      >
        <NotificationCardToaster.Container
          style={{ borderBottomColor: "transparent" }}
        >
          <NotificationCard.ContainerLeft>
            {!notification.read && <RedDot />}
            <NotificationCard.ImageContainer>
              <ProfileImage
                url={notification.creator.profileImg}
                gender={notification.creator.gender}
              />
            </NotificationCard.ImageContainer>
          </NotificationCard.ContainerLeft>

          <NotificationCard.ContainerCenter>
            <NotificationCard.TitleContainer>
              <p>
                <strong>
                  <span>{creator.username}</span> Invited you to the team:
                </strong>
              </p>
            </NotificationCard.TitleContainer>
            <TeamNameContainer id="team-name-container">
              <p> {notification.teamInvitationNotification.team.teamName}</p>
            </TeamNameContainer>

            <ButtonsContainer id="invite-buttons-container">
              <DenyButton id="deny-button" onClick={denyInvite}>
                <p style={{ marginLeft: 10, fontSize: "14px" }}>Deny:</p>
                <CloseOutlineIcon
                  style={{
                    fontSize: 15,
                    marginLeft: 5,
                    marginTop: 5,
                    marginBottom: 5,
                    color: "red",
                  }}
                />
              </DenyButton>

              <AcceptButton id="accept-button" onClick={acceptInvite}>
                <p style={{ marginLeft: 10, fontSize: "14px" }}>Accept:</p>
                <CheckOutlineIcon
                  style={{
                    fontSize: 15,
                    marginLeft: 5,
                    marginTop: 5,
                    marginBottom: 5,
                    color: "green",
                  }}
                />
              </AcceptButton>
            </ButtonsContainer>
          </NotificationCard.ContainerCenter>

          {/* <NotificationCard.ContainerRight>
            <NotificationCard.Timestamp>
              <FromNowDate date={notification.createdOn} />
            </NotificationCard.Timestamp>
          </NotificationCard.ContainerRight> */}
        </NotificationCardToaster.Container>
      </Container>
    </NotificationToaster>,
    { placement: "topEnd", duration }
  );
};

const openProjectLikeToaster = (
  notification: Notification,
  like: Like,
  creator: IUserSession,
  duration: number,
  nav: () => void,
  theme: any
) => {
  toaster.push(
    <NotificationToaster
      data-theme={theme}
      id="notification-toaster"
      style={{ padding: 0 }}
      type="info"
      header="New Project Like!"
      closable
    >
      <Container
        onClick={() => {
          nav();
          toaster.clear();
        }}
      >
        <NotificationCardToaster.Container
          style={{ borderBottomColor: "transparent" }}
        >
          <NotificationCard.ContainerLeft>
            {!notification.read && <RedDot />}
            <NotificationCard.ImageContainer>
              <ProfileImage
                url={notification.creator.profileImg}
                gender={notification.creator.gender}
              />
            </NotificationCard.ImageContainer>
          </NotificationCard.ContainerLeft>

          <NotificationCard.ContainerCenter>
            <NotificationCard.TitleContainer>
              <p>
                <strong>
                  <span>{creator.username}</span> Liked your project:
                </strong>
              </p>
            </NotificationCard.TitleContainer>
            <NotificationCard.BodyContainer>
              <NotificationCard.CommentBody style={{ textAlign: "center" }}>
                Click to view project
              </NotificationCard.CommentBody>
            </NotificationCard.BodyContainer>
          </NotificationCard.ContainerCenter>

          <NotificationCard.ContainerRight>
            <NotificationCard.Timestamp>
              <FromNowDate date={notification.createdOn} />
            </NotificationCard.Timestamp>
          </NotificationCard.ContainerRight>
        </NotificationCardToaster.Container>
      </Container>
    </NotificationToaster>,
    {
      placement: "topEnd",
      duration,
    }
  );
};

const AUTO_HIDE_TIMER = 4500;

export default function useNotificationToaster() {
  // get app navigation to generate links in the notifications
  const {
    navigateToProjectDetailsPage,
    navigateToTeamProjectDetailsPage,
    navigateToTeamPage,
  } = useAppNavigation();

  const { markAsRead } = useNotifications();

  const theme = useAppSelector(getColorTheme);

  const displayCommentNotification = (data: string) => {
    const notification = JSON.parse(data) as Notification;
    const nav = () => {
      navigateToProjectDetailsPage(
        notification.projectCommentNotification.comment.post._id,
        notification.projectCommentNotification.comment._id
      );
    };

    openProjectCommentToaster(
      notification,
      notification.projectCommentNotification.comment,
      notification.creator,
      AUTO_HIDE_TIMER,
      nav,
      theme
    );

    dispatchSetNotificationAlert();
    dispatchAppendSocketNotification(notification);
  };

  const displayTeamCommentNotification = (data: string) => {
    const notification = JSON.parse(data) as Notification;

    const nav = () => {
      navigateToTeamProjectDetailsPage(
        notification.teamProjectCommentNotification.comment.post._id,
        notification.teamProjectCommentNotification.comment._id
      );
    };

    openTeamProjectCommentToaster(
      notification,
      notification.teamProjectCommentNotification.comment,
      notification.creator,
      AUTO_HIDE_TIMER,
      nav,
      theme
    );

    dispatchSetNotificationAlert();
    dispatchAppendSocketNotification(notification);
  };

  const displayTeamInviteNotification = (data: string) => {
    const notification = JSON.parse(data) as Notification;

    const onMarkAsRead = () => markAsRead(notification._id);

    // const { accept, deny } = useTeamNotificationCard(notification);

    const nav = () => {
      navigateToTeamPage(notification.teamInvitationNotification.team._id);
    };

    /**
     * Makes server request to accept a team invitation
     */
    const acceptInvite = async () => {
      try {
        // make api req to accept
        await acceptTeamInvitation(
          notification.teamInvitationNotification.team._id
        );
        // update in redux
        dispatchAcceptNotification(notification._id);

        // mark as read
        onMarkAsRead();
        // navigate to team page
        nav();
      } catch (e) {
        serverErrorHandler(e);
      }
    };

    /**
     * Makes server request to deny a team invitation
     */
    const denyInvite = async () => {
      try {
        // make api req to deny
        await denyTeamInvitation(
          notification.teamInvitationNotification.invitation._id
        );

        // mark as read
        onMarkAsRead();
        // update in redux
        dispatchDenyNotification(notification._id);
      } catch (e) {
        serverErrorHandler(e);
      }
    };

    // const acceptInvite = () => {
    //   accept()
    //     .then(() => {
    //       // The code inside this block will execute only when accept() is successful
    //       nav();
    //     })
    //     .catch((error) => {
    //       // The code inside this block will execute only when accept() fails
    //       console.log("Error accepting the invite: ", error);
    //     });
    // };

    // const denyInvite = () => {
    //   deny();
    // };

    openTeamInviteToaster(
      notification,
      notification.creator,
      AUTO_HIDE_TIMER,
      nav,
      denyInvite,
      acceptInvite,
      theme
    );

    dispatchSetNotificationAlert();
    dispatchAppendSocketNotification(notification);
  };

  const displayProjectLikeNotification = (data: string) => {
    const notification = JSON.parse(data) as Notification;

    const nav = () => {
      navigateToProjectDetailsPage(
        notification.projectLikeNotification.project._id
      );
    };

    openProjectLikeToaster(
      notification,
      notification.projectLikeNotification.like,
      notification.creator,
      AUTO_HIDE_TIMER,
      nav,
      theme
    );

    dispatchSetNotificationAlert();
    dispatchAppendSocketNotification(notification);
  };

  return {
    displayCommentNotification,
    displayTeamCommentNotification,
    displayTeamInviteNotification,
    displayProjectLikeNotification,
  };
}
