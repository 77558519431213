import { createRoot } from "react-dom/client";
import React from "react";
import "./index.scss";
// translation lib
import "./i18n";
// styles
import "bootstrap/dist/css/bootstrap.min.css"; // bootstrap
import "./styles/main.scss";

// for accessibility report
import reportAccessibility from "util/reportAccessibility";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/compat/auth";
import sendToVercelAnalytics from "./vitals";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import {
//   createRoutesFromChildren,
//   matchRoutes,
//   useLocation,
//   useNavigationType,
//   useRoutes,
// } from "react-router-dom";

// eslint-disable-next-line no-console
/**
 * Init sentry
 */
// Sentry.init({
//   enabled: process.env.REACT_APP_SENTRY_ENVIRONMENT !== "development",
//   environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
//   dsn: "https://d360bfdf7cf4416696c43be7f562c178@o4504732643557376.ingest.sentry.io/4504732645064704",
//   integrations: [
//     new Sentry.Replay(),
//     new BrowserTracing({
//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes
//       ),
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.25,
//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0.1,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
// });

// export const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = createRoot(document.getElementById("root") as HTMLElement);

// eslint-disable-next-line react/react-in-jsx-scope
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

reportAccessibility(React);

reportWebVitals(sendToVercelAnalytics);
