import { useAppSelector } from "store/hooks";
import { getUserViewNotificationAlert } from "store/slices/userNotifications";
import hideNotificationAlert from "../api/hideNotificationAlert";
import { serverErrorHandler } from "../../../services/server-error.service";
import { dispatchHideUserNotificationAlert } from "../../../store/dispatcher";

/**
 *
 * @returns
 */
export default function useNotificationAlertIcon() {
  const viewNotificationAlert = useAppSelector(getUserViewNotificationAlert);
  /**
   * Updates flag to hide user notification icon
   */
  const hide = async () => {
    try {
      dispatchHideUserNotificationAlert();
      await hideNotificationAlert();
    } catch (e) {
      serverErrorHandler(e);
    }
  };
  return {
    viewNotificationAlert,
    hide,
  };
}
