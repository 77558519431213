import acceptTeamInvitation from "features/teams/api/acceptTeamInvitation";
import denyTeamInvitation from "features/teams/api/denyTeamInvitation";
import { useState } from "react";
import { serverErrorHandler } from "services/server-error.service";
import {
  dispatchAcceptNotification,
  dispatchDenyNotification,
} from "store/dispatcher";
import { Notification } from "..";

export default function useTeamNotificationCard(notification: Notification) {
  const { teamInvitationNotification } = notification;
  const { invitation, team } = teamInvitationNotification;

  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const [isDenying, setIsDenying] = useState<boolean>(false);

  /**
   * Makes server request to accept a team invitation
   */
  const accept = async () => {
    try {
      setIsAccepting(true);
      // make api req to accept
      await acceptTeamInvitation(team._id);
      // update in redux
      dispatchAcceptNotification(notification._id);
      setIsAccepting(false);
    } catch (e) {
      setIsAccepting(false);
      serverErrorHandler(e);
    }
  };

  /**
   * Makes server request to deny a team invitation
   */
  const deny = async () => {
    try {
      setIsDenying(true);
      // make api req to deny
      await denyTeamInvitation(invitation._id);
      // update in redux
      dispatchDenyNotification(notification._id);
      setIsDenying(false);
    } catch (e) {
      setIsDenying(false);
      serverErrorHandler(e);
    }
  };

  return {
    accept,
    deny,
    isAccepting,
    isDenying,
  };
}
