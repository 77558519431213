import React, { Suspense } from "react";
import PageLoader from "../components/Elements/Loaders/PageLoader/PageLoader";
import lazyImport from "../util/lazyImport";

// import auth routing file lazily
const { AuthRouting } = lazyImport(
  () => import("../features/auth/routes/index"),
  "AuthRouting"
);

const publicRoutes = [
  {
    path: "/*",
    element: (
      <Suspense fallback={<PageLoader theme="dark" isVisible fullscreen />}>
        <AuthRouting />
      </Suspense>
    ),
  },
];

export default publicRoutes;
