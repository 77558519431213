import React from "react";
import styled from "styled-components";

interface ILoaderProps {
  size: number;
}

const Container = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: rotate(45deg);
  transform: scale(0.8);
`;

const Center = styled.div<{ size: number }>`
  width: ${(props) => props.size * 0.2}px;
  height: ${(props) => props.size * 0.2}px;
  background-color: var(--color-loader-center);
  border-radius: 50%;
`;

const InnerItem = styled.div<{ size: number }>`
  width: ${(props) => props.size * 0.2}px;
  height: ${(props) => props.size * 0.2}px;
  border-radius: 50%;
  background-color: var(--color-loader-inner);
`;

const OuterItem = styled.div<{ size: number }>`
  width: ${(props) => props.size * 0.25}px;
  height: ${(props) => props.size * 0.25}px;
  background-color: var(--color-loader-inner);
  border-radius: 50%;
`;

const Outer = styled.div<{ size: number }>`
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: space-around;
  justify-content: space-between;
  gap: ${(props) => props.size * 0.4}px;
  animation: anim_outer 2000ms ease-in-out 200ms infinite;

  @keyframes anim_outer {
    from {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Inner = styled.div<{ size: number }>`
  position: absolute;
  display: grid;
  gap: ${(props) => props.size * 0.1}px;
  grid-template-columns: 1fr 1fr;
  align-content: space-around;
  justify-content: space-between;
  animation: anim_inner 2000ms ease-in-out infinite;

  @keyframes anim_inner {
    from {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
/**
 * Renders screen loader. Loader disappears 400ms
 * after isVisible is set to false
 * @param param0
 * @returns
 */
function PageLoader(props: ILoaderProps) {
  const { size } = props;

  return (
    <Container size={size}>
      <Center size={size} />

      <Inner size={size}>
        <InnerItem size={size} />
        <InnerItem size={size} />
        <InnerItem size={size} />
        <InnerItem size={size} />
      </Inner>

      <Outer size={size}>
        <OuterItem size={size} />
        <OuterItem size={size} />
        <OuterItem size={size} />
        <OuterItem size={size} />
      </Outer>
    </Container>
  );
}

export default PageLoader;
