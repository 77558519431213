import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { serverErrorHandler } from "../services/server-error.service";
import {
  dispatchLogout,
  dispatchSetUser,
  dispatchSetUserNotifications,
} from "../store/dispatcher";
import getLoggedInUser from "../features/profile/api/getLoggedInUser";

/**
 * This hook will fetch user profile data for the logged in user.
 * The data will only be fetched if there is user session token
 * but no user session profile data
 */
export default function useAuth() {
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    /**
     * Makes request for user data. Data is stored
     * in redux store
     */
    async function fetchUserAsync() {
      try {
        const response = await getLoggedInUser();

        dispatchSetUser(response.user);
        dispatchSetUserNotifications({
          notifications: response.notifications.docs,
          totalPages: response.notifications.totalPages,
          totalDocs: response.notifications.totalDocs,
          page: response.notifications.page,
          limit: response.notifications.limit,
          hasNextPage: response.notifications.hasNextPage,
          totalUnread: response.notifications.totalUnread,
          viewNotificationAlert: response.user.viewNotificationAlert,
        });
      } catch (e) {
        serverErrorHandler(e);
      }
      setIsDone(true);
    }

    // listen for firebase user state change
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // since we have user, fetch user data
        fetchUserAsync();
      } else {
        // user is not logged, clear redux store
        dispatchLogout();
        setIsDone(true);
      }
    });
  }, []);

  return { isDone };
}
