import { IHncUserSearchResult } from "types/user.interface";
import { makeGetRequest } from "config/axios.config";

// base API path for api requests
const BASE_URL = "/hustlencode/search";

/**
 * Makes API request for users profile data
 * @param username The user's username
 * @returns
 * - User profile data
 */

/**
 * Makes API request for random users
 * @param count
 * @returns
 */
const getRandUserApi = async (
  count: number
): Promise<IHncUserSearchResult[]> => {
  const url = `${BASE_URL}/random-profiles/?count=${count}`;

  const response = await makeGetRequest(url);

  // return user token
  return response.data.payload;
};

export default getRandUserApi;
