import { notificationSocketUrl } from "constants/api.constants";
import { useState, useEffect } from "react";
import io, { Socket } from "socket.io-client";
import { useAppSelector } from "store/hooks";
import { getUser } from "store/slices/userSessionSlice";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DefaultEventsMap } from "@socket.io/component-emitter";
import useTeamNotification from "./useNotificationToaster";

let socket: Socket<DefaultEventsMap, DefaultEventsMap> | null = null;

/**
 * Hook for connecting to notification server socket
 * @param user
 * @returns
 */
export default function useSocket() {
  const [isConnected, setIsConnected] = useState(false);

  const user = useAppSelector(getUser);

  const {
    displayCommentNotification,
    displayTeamCommentNotification,
    displayTeamInviteNotification,
    displayProjectLikeNotification,
  } = useTeamNotification();

  useEffect(() => {
    if (user) {
      socket = io(notificationSocketUrl.API_URL || "", {
        autoConnect: true,
      });

      // append user id to socket connection
      const id = user._id;
      socket.auth = { id };
      socket.connect();

      /*
       * listen for connection errors
       */
      // socket.on("connect_error", (err: any) => {
      //    console.log(err);
      // });

      /**
       * listen for successful connection
       */
      socket.on("connect", () => {
        // console.log(`Connected to notification socket with id:`, id);
        setIsConnected(true);
      });

      /**
       * listen for socket disconnection
       */
      socket.on("disconnect", () => {
        // console.log("disconnected");
        setIsConnected(false);
      });

      /**
       * listen for project comments
       */
      socket.on("project-comment-notification", (data: string) =>
        displayCommentNotification(data)
      );

      socket.on("team-project-comment-notification", (data: string) =>
        displayTeamCommentNotification(data)
      );

      socket.on("team-invite-notification", (data: string) =>
        displayTeamInviteNotification(data)
      );

      socket.on("project-like-notification", (data: string) =>
        displayProjectLikeNotification(data)
      );
    }
    return () => {
      if (socket) {
        // close all listeners
        socket.removeAllListeners();
      }
    };
  }, [user]);

  return { isConnected };
}
