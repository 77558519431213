import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userSessionReducer from "./slices/userSessionSlice";
import userNotificationReducer from "./slices/userNotifications";
import userEditorReducer from "./slices/userEditorSlice";
import userFeedReducer from "./slices/userFeedSlice";

/**
 * Create redux store by combing all the reducer slices
 */
export const store = configureStore({
  reducer: {
    userSession: userSessionReducer, // Reducer to handle user session data
    userNotifications: userNotificationReducer, //  reducer for user notifications
    userEditor: userEditorReducer,
    userFeed: userFeedReducer,
  },
  devTools: process.env.NODE_ENV === "development", // only enable in dev
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
