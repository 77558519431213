import { getAuth, signOut } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getChallengesLink,
  getCreateChallengeLink,
  getCreateProjectLink,
  getEditProfileAboutLink,
  getEditProjectPage,
  getEditTeamProjectUrl,
  getFeedPage,
  getProjectDetailsPageUrl,
  getTeamProjectDetailsPageUrl,
  getTeamProfileLink,
  getUserProfileLink,
  getUserProfilePostsLink,
  getUserProfileTeamInvitationsLink,
  getUserProfileTeamsLink,
  getUserSearchUrl,
  getChallengeDetailsLink,
  getProjectFullPageUrl,
  getUserProfileAboutLink,
} from "../util/navigation.util";
import { IDropdownMenuItem } from "../types/dropdown.interface";
import { dispatchLogout } from "../store/dispatcher";
import {
  getHistory,
  getPreviousPath,
  getUser,
} from "../store/slices/userSessionSlice";
import useAnalytics from "./useAnalytics";
import { useAppSelector } from "../store/hooks";
import settingsIcon from "../assets/images/navbar/settings_icon.png";
import signOutIcon from "../assets/images/navbar/signOut_icon.png";
/**
 * Navigation links
 * @param user
 * @returns
 */
export default function useAppNavigation() {
  const user = useAppSelector(getUser);
  const prevPath = useAppSelector(getPreviousPath);
  const history = useAppSelector(getHistory);

  // use app navigation
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // use analytics
  const { trackEditProject, trackProjectFullPage } = useAnalytics();

  // helper function for navigation
  const navTo = (url: string) => navigate(url);

  /**
   * Signs user out and removes user
   * session data
   */
  const onLogout = async () => {
    // get user session
    const auth = getAuth();
    // sign out user
    await signOut(auth);
    dispatchLogout();
  };

  /**
   * navigate user to page with search results
   * @param search
   */
  const goToUserSearch = (search: string) => navTo(getUserSearchUrl(search));

  /**
   *
   */
  const navigateToPost = (id: string) => navTo(`/projects/post/${id}`);

  /**
   * Takes user to code editor page to edit project
   */
  const navigateToEditPost = (id: string) => {
    trackEditProject();
    navTo(getEditProjectPage(id));
  };

  /**
   * Redirects user to the page with the project
   * full view
   * @param id
   */
  const navigateToProjectFullPage = (id: string) => {
    trackProjectFullPage();
    navTo(getProjectFullPageUrl(id));
  };

  /**
   * Redirects user to the page with the project
   * details. This includes the project comment section. If comment id is provided, it will scroll to the comment
   * @param id
   * @param comment optional comment id
   * @returns
   */
  const navigateToProjectDetailsPage = (id: string, comment?: string) => {
    navTo(getProjectDetailsPageUrl(id, comment));
  };

  /**
   * Redirects user to the page with the team project
   * details. This includes the project comment section.
   * @param id
   */
  const navigateToTeamProjectDetailsPage = (id: string, comment?: string) => {
    navTo(getTeamProjectDetailsPageUrl(id, comment));
  };

  /**
   * Redirects user to the page with the team project
   * full view
   * @param id
   */
  const navigateToTeamProjectFullPage = (id: string) => {
    navTo(`/team-project/full-page/${id}`);
  };

  /**
   * Redirect user to edit team project page
   */
  const navigateToEditTeamProject = (teamId: string, projectId: string) => {
    navTo(getEditTeamProjectUrl(teamId, projectId));
  };

  /**
   *
   */
  const navigateToTeamPage = (teamId: string) =>
    navTo(getTeamProfileLink(teamId));

  /**
   *
   */
  const navigateToFeed = (type: string) => {
    navTo(getFeedPage(type));
  };

  /**
   * Takes user to their profile page.
   * It contains the about and interests section
   */
  const navigateToProfile = (id: string) => navTo(getUserProfileLink(id));

  /**
   * Takes user to their profile page with their teams
   * @param id
   */
  const navigateToProfileTeams = (filter = "your-teams") => {
    navTo(getUserProfileTeamsLink(user?._id || "", filter));
  };

  /**
   * Takes user to challenges page
   * @param id
   */
  const navigateToChallenges = () => {
    navTo(getChallengesLink());
  };

  /**
   * Takes user to the profile page with their team invitations
   * @param id
   * @param filter
   */
  const navigateToProfileTeamInvitations = (id: string) => {
    navTo(getUserProfileTeamInvitationsLink(id));
  };
  /**
   * Takes user to the profile settings page.
   * Where they can update their profile information
   */
  const navigateToProfileSettings = () => {
    navTo("/account/edit/profile");
  };

  /**
   * Takes user to the walkthroughs page.
   * Where they can access the guided tours
   */
  // const navigateToProfileWalkthroughs = (id: string) => {
  //   navTo(`${getUserProfileLink(id)}/walkthroughs`);
  // };
  /**
   * Takes user to editor page where they can edit the code for
   * their profile page layout.
   */
  const navigateToEditProfileLayout = () => {
    navTo("/projects/profile/layout");
  };

  const navigateToSearchResults = (searchValue: string) => {
    navTo(getUserSearchUrl(searchValue));
  };

  const navigateToEditProfileCover = () => {
    navTo("/projects/profile/cover");
  };

  /**
   * Takes user to editor page where they can edit the code for the
   * about section in their profile page
   * @returns
   */
  const navigateToEditProfileAbout = () => navTo(getEditProfileAboutLink());

  /**
   * Takes user to profile page with the profile posts feed
   */
  const navigateToProfilePosts = (id: string) =>
    navTo(getUserProfilePostsLink(id));

  /**
   * Takes user to profile page with the profile posts feed
   */
  const navigateToProfileAbout = (id: string) =>
    navTo(getUserProfileAboutLink(id));

  /**
   * Takes user to editor page to create new
   * project
   */
  const navigateToCreateProject = () => {
    navTo(getCreateProjectLink());
  };

  /**
   * Takes user to page to create new challenge
   */
  const navigateToCreateChallenge = () => navTo(getCreateChallengeLink());

  /**
   * Takes user to page to view challenge details
   */
  const navigateToViewChallengeDetails = (id: string) =>
    navTo(getChallengeDetailsLink(id));

  // Dropdown menu items
  const navigationLinks: IDropdownMenuItem[] = [
    {
      label: "Settings",
      action: () => navigateToProfileSettings(),
      icon: settingsIcon,
      iconAlt: "settings icon",
    },

    {
      label: "Sign Out",
      action: () => onLogout(),
      icon: signOutIcon,
      iconAlt: "sign out icon",
    },
  ];

  // Handles back navigation
  const goBack = () => {
    const { length } = history;

    // if we have more than one path in history
    if (length > 1) {
      // go to home since previous path is the same as current page
      if (history[history.length - 2] === pathname) {
        navigate("/");
      } else {
        // go the previous page
        navigate(history[history.length - 2]);
      }
    } else if (history[0] !== pathname && history[0] !== `${pathname}?`) {
      // go to stored previous path since its different
      navigate(prevPath);
    } else {
      navigate("/");
    }
  };

  return {
    goBack,
    navigationLinks,
    getUserProfilePostsLink,
    getUserProfileLink,
    getUserProfileTeamsLink,
    getUserProfileTeamInvitationsLink,
    navTo,
    onLogout,
    navigateToFeed,
    getUserSearchUrl,
    goToUserSearch,
    navigateToPost,
    navigateToEditPost,
    navigateToProjectFullPage,
    navigateToEditTeamProject,
    navigateToTeamPage,
    navigateToTeamProjectFullPage,
    navigateToProfile,
    navigateToProfileTeams,
    navigateToProfileTeamInvitations,
    navigateToProfileSettings,
    navigateToEditProfileLayout,
    navigateToEditProfileCover,
    navigateToProfilePosts,
    navigateToProfileAbout,
    getTeamProfileLink,
    navigateToEditProfileAbout,
    navigateToCreateProject,
    navigateToCreateChallenge,
    navigateToViewChallengeDetails,
    navigateToChallenges,
    navigateToSearchResults,
    navigateToProjectDetailsPage,
    navigateToTeamProjectDetailsPage,
    links: {
      teamPage: (id: string) => `/teams/${id}`,
    },
  };
}
