import React from "react";
import { Dropdown } from "rsuite";
import styled from "styled-components";

interface MenuItemProps {
  id?: string;
  action: () => void;
  label: string;
  type?: "default" | "danger";
}

const Container = styled(Dropdown.Item)`
  text-align: left;
  padding: 4px 25px !important;
  color: var(--color-black) !important;
  font-size: 15px;

  &:hover {
    color: ${(props) =>
      props.type === "danger"
        ? "#fff !important"
        : "var(--color-white) !important"};
    background-color: ${(props) =>
      props.type === "danger" ? "var(--color-dark-red)" : "var(--color-black)"};
  }
`;

export default function MenuItem(props: MenuItemProps) {
  const { id, action, label, type } = props;
  return (
    <Container id={id} type={type || "default"} key={1} onClick={action}>
      {label}
    </Container>
  );
}

MenuItem.defaultProps = {
  id: "",
  type: "default",
};
