import { Step } from "react-joyride";
import useStartTutorial from "../features/onboarding/hooks/useStartTutorial";

// This hook provides a way to start a tutorial with a delay and then uses a cleanup function
export default function useTimeoutCleanup() {
  const { startTutorial } = useStartTutorial();

  // This function starts a tutorial with a specified delay, and returns a function to cleanup
  const startTutorialWithDelay = (
    tutorialSteps: Step[], // The steps to use in the tutorial
    // The name of the tutorial to start (mainTutorial, projectTutorial, settingsTutorial)
    tutorialTarget: any,
    timeDelay: number // The delay in milliseconds before starting the tutorial
  ) => {
    // Use setTimeout to start the tutorial after the specified delay
    const timeoutId = setTimeout(() => {
      startTutorial(tutorialSteps, tutorialTarget);
    }, timeDelay);

    // Return a function to cleanup
    return () => {
      clearTimeout(timeoutId);
    };
  };

  // This function is for general use. It has timeout and cleanup function to clear the timeout
  const cleanTimeout = (
    callBack: () => void,
    timeDelay: number // The delay in milliseconds before starting
  ) => {
    const timeoutId = setTimeout(() => {
      callBack();
    }, timeDelay);
    // Return a function to cleanup
    return () => {
      clearTimeout(timeoutId);
    };
  };

  // Return the startTutorialWithDelay function
  return { startTutorialWithDelay, cleanTimeout };
}
