/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification } from "../../features/notifications/types/index";
import { RootState } from "../store";

export interface IUserNotificationState {
  notifications: Notification[];
  page: number;
  limit: number;
  totalPages: number;
  hasNextPage: boolean;
  totalDocs: number;
  initFetch: boolean;
  totalUnread: number;
  viewNotificationAlert: boolean;
}

export interface ISetUserNotificationsPayload {
  notifications: Notification[];
  page: number;
  limit: number;
  totalPages: number;
  hasNextPage: boolean;
  totalDocs: number;
  totalUnread: number;
  viewNotificationAlert: boolean;
}

// initial state for current user session
const initialState: IUserNotificationState = {
  notifications: [],
  page: 1,
  limit: 5,
  totalPages: 0,
  hasNextPage: false,
  totalDocs: 0,
  initFetch: false,
  totalUnread: 0,
  viewNotificationAlert: false,
};

export const userNotificationSlice = createSlice({
  name: "userNotifications",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // sets notifications
    setNotifications: (
      state,
      action: PayloadAction<ISetUserNotificationsPayload>
    ) => {
      state.notifications = action.payload.notifications;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.totalPages = action.payload.totalPages;
      state.hasNextPage = action.payload.hasNextPage;
      state.totalDocs = action.payload.totalDocs;
      state.initFetch = true;
      state.totalUnread = action.payload.totalUnread;
      state.viewNotificationAlert = action.payload.viewNotificationAlert;
    },
    // appends notification that comes in via web sockets
    appendSocketNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications = [action.payload, ...state.notifications];
      state.totalDocs += 1;
      state.totalUnread += 1;
    },
    // marks matching notification as read
    markNotificationAsRead: (state, action: PayloadAction<string>) => {
      state.notifications.forEach((notification) => {
        if (notification._id === action.payload) {
          notification.read = true;
        }
      });
    },

    // marks matching notification as read
    acceptTeamNotification: (state, action: PayloadAction<string>) => {
      const i = state.notifications.map((n) => n._id).indexOf(action.payload);

      if (i > -1) {
        state.notifications[i].teamInvitationNotification.invitation.status =
          "accepted";
      }
    },

    // marks matching notification as read
    denyTeamNotification: (state, action: PayloadAction<string>) => {
      const i = state.notifications.map((n) => n._id).indexOf(action.payload);

      if (i > -1) {
        state.notifications[i].teamInvitationNotification.invitation.status =
          "denied";
      }
    },

    markAllAsRead: (state) => {
      state.notifications = state.notifications.map((n) => ({
        ...n,
        read: true,
      }));
      state.totalUnread = 0;
    },

    setNotificationAlert: (state) => {
      state.viewNotificationAlert = true;
    },
    // update notification alert
    hideNotificationAlert: (state) => {
      state.viewNotificationAlert = false;
    },
  },
  extraReducers(builder) {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const {
  setNotifications,
  appendSocketNotification,
  markNotificationAsRead,
  denyTeamNotification,
  acceptTeamNotification,
  markAllAsRead,
  hideNotificationAlert,
  setNotificationAlert,
} = userNotificationSlice.actions;

// get user notifications
export const getUserNotifications = (state: RootState) =>
  state.userNotifications.notifications;

export const getUserViewNotificationAlert = (state: RootState) =>
  state.userNotifications.viewNotificationAlert;

export const getNotificationPage = (state: RootState) =>
  state.userNotifications.page;

export const getNotificationLimit = (state: RootState) =>
  state.userNotifications.limit;

export const getNotificationTotalPages = (state: RootState) =>
  state.userNotifications.totalPages;

export const getNotificationTotal = (state: RootState) =>
  state.userNotifications.totalDocs;

export const getInitFetch = (state: RootState) =>
  state.userNotifications.initFetch;

export const getNotificationHasNextPage = (state: RootState) =>
  state.userNotifications.hasNextPage;

export const getTotalUnread = (state: RootState) =>
  state.userNotifications.totalUnread;

export default userNotificationSlice.reducer;
