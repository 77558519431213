import { getAuth } from "firebase/auth";

/**
 * Makes request for refreshed token id if user
 * is logged in.
 * The refreshed token is then set in the request headers
 * @returns
 */
export default async function refreshTokenId(): Promise<string | null> {
  // get auth session
  const auth = getAuth();
  // check for authorized user
  const user = auth.currentUser;

  if (user) {
    // get user id token
    const token = await user.getIdToken(false);
    if (token) {
      return Promise.resolve(token);
    }
    return Promise.resolve(null);
  }
  return Promise.resolve(null);
}
