import { Whisper } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import React, { useRef } from "react";
import { useAppSelector } from "store/hooks";
import { getTotalUnread } from "store/slices/userNotifications";
import useScroll from "hooks/useScroll";
import useNotificationAlertIcon from "../hooks/useNotificationAlert";
import NotificationHubMenu from "./NotificationHubMenu";

const TriggerContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 28px;
  margin-right: 16px;
  opacity: 0.8;
  position: relative;

  svg {
    color: var(--color-light-gray);
    height: 28px;
  }

  &:hover {
    opacity: 1;
  }
`;

const RedDot = styled.div<{ num: number }>`
  background-color: var(--color-red);
  border-radius: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -6px;
  right: ${(props) => (props.num > 9 ? -12 : -12)}px;
  padding: 3px ${(props) => (props.num > 9 ? 6 : 8)}px;

  p {
    color: #fff;
    font-size: 14px;
  }
`;

/**
 * Renders a icon trigger that displays a menu with a list of
 * notifications
 * @returns
 */
function NotificationHubTrigger() {
  // ref to manipulate the notification menu state
  const whisperRef = useRef<any>(null);

  // hook for notification bell icon alert
  const { viewNotificationAlert, hide: hideAlertIcon } =
    useNotificationAlertIcon();

  // get total unread notifications
  const totalUnread = useAppSelector(getTotalUnread);

  const { top } = useScroll();

  /**
   * prevent user scroll
   */
  const preventScroll = () => {
    document.getElementById("html-root")?.classList.add("noscroll");

    document.getElementById("html-root")?.style.setProperty("top", `${-top}px`);
  };

  /**
   * enable scroll on menu close
   */
  const onMenuClose = () => {
    const scrollTop = parseInt(
      document.getElementById("html-root")?.style.top || "0",
      10
    );

    document.getElementById("html-root")?.classList.remove("noscroll");
    window.scrollTo({
      behavior: "instant" as ScrollBehavior,
      top: -scrollTop,
      left: 0,
    });
  };

  /**
   * Stops scroll on menu open
   */
  const onMenuOpen = () => {
    preventScroll();

    // make request to hide icon alert
    // since user has opened notification menu
    if (viewNotificationAlert) {
      hideAlertIcon();
    }
  };

  /**
   * Closes the notification menu
   */
  const closeMenu = () => {
    if (whisperRef && whisperRef.current) {
      whisperRef.current.close();
    }
  };

  /**
   * Display notification red dot
   * if user has not opened notification menu since
   * latest notification
   */
  if (viewNotificationAlert) {
    return (
      <Whisper
        ref={whisperRef}
        placement="bottomEnd"
        speaker={<NotificationHubMenu close={closeMenu} />}
        trigger="active"
        onExiting={onMenuClose}
        onEntering={onMenuOpen}
      >
        <TriggerContainer>
          {totalUnread !== 0 && (
            <RedDot num={totalUnread}>
              <p>{totalUnread > 9 ? "9+" : totalUnread}</p>
            </RedDot>
          )}

          <FontAwesomeIcon icon={faBell} />
        </TriggerContainer>
      </Whisper>
    );
  }

  return (
    <Whisper
      ref={whisperRef}
      placement="bottomEnd"
      speaker={<NotificationHubMenu close={closeMenu} />}
      trigger="click"
      onExiting={onMenuClose}
      onEntering={onMenuOpen}
    >
      <TriggerContainer>
        <FontAwesomeIcon icon={faBell} />
      </TriggerContainer>
    </Whisper>
  );
}

export default NotificationHubTrigger;
