import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useSocket from "hooks/useSocket";
import MainLayout from "../components/Layout/MainLayout";
import PageLoader from "../components/Elements/Loaders/PageLoader/PageLoader";
import lazyImport from "../util/lazyImport";
import UserProfileProvider from "../providers/UserProfileProvider";

// user profile routes
const { ProfileRoutes } = lazyImport(
  () => import("../features/profile/routes/index"),
  "ProfileRoutes"
);
// settings routes
const { SettingsRoutes } = lazyImport(
  () => import("../features/settings/routes/index"),
  "SettingsRoutes"
);

// posts routes
const { ProjectRoutes } = lazyImport(
  () => import("../features/projects/routes/index"),
  "ProjectRoutes"
);

// posts routes
const { ProjectEditorRoutes } = lazyImport(
  () => import("../features/project-editor/routes/index"),
  "ProjectEditorRoutes"
);

// team project routes
const { TeamProjectEditorRoutes } = lazyImport(
  () => import("../features/team-project-editor/routes/index"),
  "TeamProjectEditorRoutes"
);

// teams routes
const { TeamsRoutes } = lazyImport(
  () => import("../features/teams/routes/index"),
  "TeamsRoutes"
);

// teams routes
const { SearchRoutes } = lazyImport(
  () => import("../features/search/routes/index"),
  "SearchRoutes"
);

// feed routes
const { FeedRoutes } = lazyImport(
  () => import("../features/feed/routes/index"),
  "FeedRoutes"
);

// route for team projects
const { TeamProjectRoutes } = lazyImport(
  () => import("../features/team-projects/routes/index"),
  "TeamProjectRoutes"
);

// const { ResourceRoutes } = lazyImport(
//   () => import("features/resources"),
//   "ResourceRoutes"
// );

// challenge + leadership routes
// const { ChallengeRoutes } = lazyImport(
//   () => import("features/challenges"),
//   "ChallengeRoutes"
// );

function ProjectEditorRoutesLayout() {
  return (
    <UserProfileProvider theme="dark">
      {({ user }) => (
        <Suspense fallback={<PageLoader theme="dark" isVisible fullscreen />}>
          <Outlet context={{ user }} />
        </Suspense>
      )}
    </UserProfileProvider>
  );
}

/**
 * Renders the routes. A loader is shown if user data is being fetched.
 * A loader is shown for routes that are lazy loaded
 * @returns
 */

function ProtectedRoutesLayout() {
  useSocket();
  return (
    <UserProfileProvider theme="dark">
      {({ user }) => (
        <MainLayout user={user}>
          {({ searchRef }) => (
            <Suspense
              fallback={<PageLoader theme="dark" isVisible fullscreen />}
            >
              <Outlet context={{ user, searchRef }} />
            </Suspense>
          )}
        </MainLayout>
      )}
    </UserProfileProvider>
  );
}

const protectedRoutes = [
  {
    path: "",
    element: <ProtectedRoutesLayout />,
    children: [
      // project
      { path: "project/*", element: <ProjectRoutes /> },
      // project
      { path: "team-project/*", element: <TeamProjectRoutes /> },
      // project feed
      { path: "feed/*", element: <FeedRoutes /> },
      // user profile routes
      { path: "user/*", element: <ProfileRoutes /> },
      // user settings
      { path: "account/*", element: <SettingsRoutes /> },
      // teams
      { path: "teams/*", element: <TeamsRoutes /> },
      // search
      { path: "search/*", element: <SearchRoutes /> },
      {
        path: "",
        element: <Navigate to="feed?type=user" />,
      },
      // handle wildcard path
      {
        path: "*",
        element: <Navigate to="/feed?type=user" />,
      },
    ],
  },
  {
    path: "projects",
    element: <ProjectEditorRoutesLayout />,
    children: [
      // posts
      { path: "*", element: <ProjectEditorRoutes /> },
    ],
  },
  {
    path: "team-projects",
    element: <ProjectEditorRoutesLayout />,
    children: [
      // posts
      { path: "*", element: <TeamProjectEditorRoutes /> },
    ],
  },

  // handle wildcard path
  {
    path: "*",
    element: <Navigate to="feed?type=user" />,
  },
];

export default protectedRoutes;
