import { IServerResponse } from "types/server.interface";
import { makePutRequest } from "../../../config/axios.config";

/**
 */
const denyTeamInvitation = async (id: string): Promise<IServerResponse> => {
  const url = `hustlencode/teams/deny-invitation?id=${id}`;
  const response = await makePutRequest(url, {});
  return response.data.payload;
};

export default denyTeamInvitation;
