import { IServerResponse } from "types/server.interface";
import { makePutRequest } from "../../../config/axios.config";

/**
 * Makes api request to accept the team invitation
 * @param id id of invitation
 * @returns
 */
const acceptTeamInvitation = async (id: string): Promise<IServerResponse> => {
  const url = `hustlencode/teams/accept-invitation?id=${id}`;
  const response = await makePutRequest(url, {});
  return response.data;
};

export default acceptTeamInvitation;
