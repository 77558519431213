import React, { forwardRef, useRef } from "react";
import { Popover } from "rsuite";
import styled from "styled-components";
import { getTotalUnread } from "store/slices/userNotifications";
import { getColorTheme } from "store/slices/userSessionSlice";
import Spinner from "components/Elements/Loaders/Spinner/Spinner";
import { faSyncAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroll-component";
import ProjectCommentNotificationCard from "./NotificationCards/ProjectCommentNotificationCard";
import useNotifications from "../hooks/useNotifications";
import { Notification } from "../types/index";
import { useAppSelector } from "../../../store/hooks";
import TeamProjectCommentNotificationCard from "./NotificationCards/TeamProjectCommentNotificationCard";
import TeamInviteNotificationCard from "./NotificationCards/TeamInviteNotificationCard";
import ProjectLikeNotificationCard from "./NotificationCards/ProjectLikeNotificationCard";

const NotificationMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 0px;
`;

const NotificationHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: solid thin var(--color-card-border);
  padding: 10px 20px 10px 20px;
  height: 50px;
`;

// Renders items in the left side of the header
const NotificationHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 21px;
    line-height: 24px;
  }
`;

const NotificationFeed = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  .menu-scroll {
    width: 100%;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: var(color-white);
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: var(color-white);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: var(--color-black);
    }
  }
`;

const EmptyFeed = styled.p`
  color: var(--color-black);
  padding: 10px 15px;
`;

const NotificationFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 12px;
  border-top: solid 1px var(--color-card-border);
`;

const NotificationMarkAsReadBtn = styled.button`
  background-color: transparent;
  height: 25px;

  &:hover {
    cursor: pointer;
  }

  p {
    line-height: 15px;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const NotificationCardLoader = styled.div`
  display: flex;
  justify-content: center;
  width: 150px;
  padding: 16px 10px;
`;

/**
 * Renders dropdown containing a list of
 * the user's notifications
 */
const NotificationHubMenu = forwardRef(
  (props: { close: () => void }, ref: any) => {
    const { close } = props;

    // get current theme value from store
    const theme = useAppSelector(getColorTheme);

    const {
      notifications,
      hasNextPage: hasMore,
      isDone,
      isMarkingAllAsRead,
      markAllAsRead,
      fetchMore,
    } = useNotifications();

    const menuContainerRef = useRef<any>(null);

    // get total unread notifications
    const totalUnread = useAppSelector(getTotalUnread);

    /**
     * Checks notification type render proper card layout
     * for the notification content
     * @param notification
     * @returns
     */
    const renderCard = (
      notification: Notification,
      handleClose: () => void
    ) => {
      if (
        notification.type === "project-comment-created" &&
        notification.projectCommentNotification.comment &&
        notification.projectCommentNotification.comment.post
      ) {
        return (
          <ProjectCommentNotificationCard
            key={notification._id}
            notification={notification}
            close={handleClose}
          />
        );
      }

      if (
        notification.type === "team-project-comment-created" &&
        notification.teamProjectCommentNotification.comment &&
        notification.teamProjectCommentNotification.comment.post
      ) {
        return (
          <TeamProjectCommentNotificationCard
            key={notification._id}
            notification={notification}
            close={handleClose}
          />
        );
      }

      if (
        notification.type === "team-invitation-created" &&
        notification.teamInvitationNotification &&
        notification.teamInvitationNotification.team &&
        notification.read === false
      ) {
        return (
          <TeamInviteNotificationCard
            key={notification._id}
            notification={notification}
            close={handleClose}
          />
        );
      }
      if (
        notification.type === "project-like-created" &&
        notification.projectLikeNotification &&
        notification.projectLikeNotification.like &&
        notification.read === false
      ) {
        return (
          <ProjectLikeNotificationCard
            key={notification._id}
            notification={notification}
            close={handleClose}
          />
        );
      }

      return null;
    };

    const renderBtnMessage = () => {
      if (isMarkingAllAsRead) {
        return <FontAwesomeIcon spin icon={faSpinner} />;
      }

      if (totalUnread === 0) {
        return null;
      }
      return <p>Mark all as read</p>;
    };
    /**
     * Is not finished fetching notifications
     */
    if (!isDone) {
      return (
        <Popover
          data-theme={theme}
          ref={ref}
          title=""
          visible
          id="notification-popover"
        >
          <NotificationCardLoader ref={menuContainerRef}>
            <Spinner size={30} />
          </NotificationCardLoader>
        </Popover>
      );
    }

    /**
     * Handle no notifications
     */
    if (notifications.length === 0) {
      return (
        <Popover
          data-theme={theme}
          ref={ref}
          title=""
          visible
          id="notification-popover"
        >
          <EmptyFeed ref={menuContainerRef}>
            You don’t have any activity yet
          </EmptyFeed>
        </Popover>
      );
    }

    /**
     * Render notification feed
     */
    return (
      <Popover
        data-theme={theme}
        ref={ref}
        title=""
        visible
        id="notification-popover"
      >
        <NotificationMenu ref={menuContainerRef}>
          {/* header */}
          <NotificationHeader>
            <NotificationHeaderLeft>
              <h1>Notifications</h1>
            </NotificationHeaderLeft>
          </NotificationHeader>
          {/* body */}
          <NotificationFeed>
            <InfiniteScroll
              className="menu-scroll"
              height={300}
              dataLength={notifications.length}
              next={fetchMore}
              hasMore={hasMore}
              style={{ overflowX: "hidden" }}
              loader={
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FontAwesomeIcon
                    style={{ color: "#282828" }}
                    spin
                    icon={faSyncAlt}
                  />
                </div>
              }
              endMessage={<p />}
            >
              {notifications.map((notification: Notification) =>
                renderCard(notification, close)
              )}
            </InfiniteScroll>
          </NotificationFeed>
          {/* footer */}
          <NotificationFooter>
            <NotificationMarkAsReadBtn onClick={markAllAsRead}>
              {renderBtnMessage()}
            </NotificationMarkAsReadBtn>
          </NotificationFooter>
        </NotificationMenu>
      </Popover>
    );
  }
);

export default NotificationHubMenu;
