import React from "react";
import { Tooltip, Whisper } from "rsuite";
import { momentDateDetailed, momentFromNow } from "services/moment.service";
import styled from "styled-components";

interface IProps {
  date: Date;
}

const TimeStamp = styled.span`
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  color: var(--color-black);
  text-transform: none !important;
`;

/**
 * Renders a message with the time that has passed
 * since provided date
 * @param props
 * @returns
 */
export default function FromNowDate({ date }: IProps) {
  // show entire date timestamp in the tooltip
  const dateTooltip = <Tooltip>{momentDateDetailed(date)}</Tooltip>;

  // display short message with time that has passed
  return (
    <Whisper placement="top" trigger="hover" speaker={dateTooltip}>
      <TimeStamp>{momentFromNow(date)}</TimeStamp>
    </Whisper>
  );
}
