import { makeGetRequest } from "config/axios.config";
import { GetNotificationsPayload } from "features/notifications/index";
import { IUserSession } from "types/user.interface";

/**
 * Makes API request for user session profile data
 * @returns user session data
 */
const getLoggedInUser = async (): Promise<{
  user: IUserSession;
  notifications: GetNotificationsPayload;
}> => {
  const url = "/hustlencode/profile";
  const response = await makeGetRequest(url);
  // return user session data
  return response.data.payload;
};

export default getLoggedInUser;
