import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { CustomProvider as RSsuiteProvider } from "rsuite";
import { useAppSelector } from "../store/hooks";
import { getColorTheme } from "../store/slices/userSessionSlice";

/**
 * Sets providers for app
 * @param param0
 * @returns
 */
export default function AppProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // get current theme value from store
  const theme = useAppSelector(getColorTheme);

  return (
    <HelmetProvider>
      <div data-theme={theme}>
        <RSsuiteProvider theme={theme}>
          <Router>{children}</Router>
        </RSsuiteProvider>
      </div>
    </HelmetProvider>
  );
}
