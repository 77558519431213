import { useEffect, useState } from "react";

export default function useIsMobile() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // console.log(`width ${width}`);
  // console.log(`pixel ratio ${window.devicePixelRatio}`);
  const isMobile = width <= 768;

  return {
    isMobile,
    width,
  };
}
