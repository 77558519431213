import React from "react";
import PageLoader from "components/Elements/Loaders/PageLoader/PageLoader";
import useAuth from "hooks/useAuth";
import { getUser } from "store/slices/userSessionSlice";
import { useRoutes } from "react-router-dom";
// import useChatbot from "hooks/useChatbot";
import usePageHistory from "hooks/usePageHistory";
import publicRoutes from "./public";
import protectedRoutes from "./protected";
import { useAppSelector } from "../store/hooks";

/**
 * Checks if the user is logged in,
 * Sets protected routes if user is logged in,
 * Otherwise sets the public routes
 * @returns
 */
export default function AppRoutes() {
  // listen for route changes and record path history
  usePageHistory();

  // check for authorized user
  const { isDone } = useAuth();

  // We disable the chatbot for now
  // // init chat bot
  // const { initializeChatra } = useChatbot();
  // initializeChatra();

  // fetch user profile
  const user = useAppSelector(getUser);

  // render protected routes if we have logged in user
  const routes = user ? protectedRoutes : publicRoutes;

  // create routes
  const element = useRoutes([...routes]);

  if (isDone) {
    return element;
  }

  // render
  return <PageLoader theme="dark" isVisible fullscreen />;
}
