export const getEditProjectPage = (id: string) => `/projects/edit/${id}`;
// link for feed page
export const getFeedPage = (type: string) => `/feed?type=${type}`;

// link for user profile page
export const getUserProfileLink = (id: string) => `/user/${id}`;

// link for team page
export const getTeamProfileLink = (teamId: string) => `/teams/${teamId}`;

// link for profile posts page
export const getUserProfilePostsLink = (id: string) => `/user/${id}/posts`;

// link for profile about page
export const getUserProfileAboutLink = (id: string) => `/user/${id}/about`;

// link for editor page to update about
export const getEditProfileAboutLink = () => "/projects/profile/about";

// link for editor page to update about
export const getEditProfileInterestsLink = () => "/projects/profile/interests";

// link for page to create new challenge page
export const getCreateChallengeLink = () => "/challenges/create-challenge";

// link for page to view all challenges
export const getChallengesLink = () => "/challenges";

// link for page to view details page
export const getChallengeDetailsLink = (id: string) =>
  `/challenges/details/${id}`;

// link for editor page to create project
export const getCreateProjectLink = () => "/projects/create";

// link for user team page
export const getUserProfileTeamsLink = (id: string, filter = "your-teams") =>
  `/user/${id}/teams?t=${filter}`;

// link for user's teams
export const getUserProfileTeamsLinkBase = (id: string) => `/user/${id}/teams`;

// link for page to view users team invitations
export const getUserProfileTeamInvitationsLink = (id: string) =>
  `/user/${id}/team-invitations`;

// link for user search result page
export const getUserSearchUrl = (search: string) => `/search/?q=${search}`;

// link for project full page view
export const getProjectFullPageUrl = (id: string) => `/project/full-page/${id}`;

/**
 * Link for project details page
 * @param id project id
 * @param comment comment id (optional)
 * @returns url to project page. If comment id is passed
 * it will be added as a query param
 */
export const getProjectDetailsPageUrl = (id: string, comment?: string) =>
  comment
    ? `/project/details/${id}?comment=${comment}`
    : `/project/details/${id}`;

/**
 * Link for project details page
 * @param id project id
 * @param comment comment id (optional)
 * @returns url to project page. If comment id is passed
 * it will be added as a query param
 */
export const getTeamProjectDetailsPageUrl = (id: string, comment?: string) =>
  comment
    ? `/team-project/details/${id}?comment=${comment}`
    : `/team-project/details/${id}`;

/**
 * Returns url for page to edit team projects
 * @param teamId the teams id
 * @param projectId the team project id
 * @returns
 */
export const getEditTeamProjectUrl = (teamId: string, projectId: string) =>
  `/team-projects/${teamId}/${projectId}/edit`;

export const getLoginUrl = () => `/login`;

export const getSignUpUrl = () => `/sign-up`;
