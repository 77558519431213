import React from "react";
import { Provider } from "react-redux";
import { store } from "../store/store";
import { injectStore } from "../store/dispatcher";

// inject so we can extract instance of store and avoid circular imports
injectStore(store);

/**
 * Sets providers for redux store.
 * Redux store provider is required for redux store
 * manipulation
 * @param param0
 * @returns
 */
export default function StoreProvider({ children }: { children: any }) {
  return <Provider store={store}>{children}</Provider>;
}
