import React from "react";
import { Dropdown } from "rsuite";
import { IUserSession } from "types/user.interface";
import { getProfileImage } from "util/profile.util";
import { IDropdownMenuItem } from "types/dropdown.interface";
// import { useAppDispatch, useAppSelector } from "store/hooks";
// import { getColorTheme, setTheme } from "store/slices/userSessionSlice";
import styled from "styled-components";
import useAppNavigation from "hooks/useAppNavigation";
import classes from "./NavBarProfileDropdown.module.scss";

interface IProps {
  user: IUserSession;
}

const Info = styled.div`
  margin-right: 10px;
  margin-left: 10px;

  p {
    text-align: center;
    text-transform: capitalize;

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .type {
    margin: 0;
    font-size: 16px;
    text-align: right;
    text-transform: capitalize;
    // color: var(--color-blue);
  }
`;

// const ToggleContainer = styled.div`
//   display: flex;
//   justify-content: center;
// `;

/**
 * Renders the profile widget in the navbar
 * @param param0
 * @returns
 */
function NavBarProfileDropdown(props: IProps) {
  const { user } = props;

  // get nav menu urls
  const { navigationLinks, navigateToProfile } = useAppNavigation();

  // get store dispatcher
  // const dispatch = useAppDispatch();

  // get current theme
  // const theme = useAppSelector(getColorTheme);

  // Handles theme switch, will be commented out for now
  // const switchTheme = () => {
  //   const newTheme: ColorTheme = theme === "light" ? "dark" : "light";
  //   // call action to update theme
  //   dispatch(setTheme(newTheme));
  // };

  const goToProfile = () => {
    navigateToProfile(user._id);
  };

  return (
    <div className={classes.profileContainer} id="profile">
      {user ? (
        <Dropdown
          trigger="hover"
          className={classes.dropdown}
          placement="bottomEnd"
          onClick={goToProfile}
          renderToggle={(prop: any, ref: any) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...prop} ref={ref} className={classes.profile}>
              <div className={classes.thumbnail}>
                <img
                  style={{ width: "100%" }}
                  src={getProfileImage(user.profileImg, user.gender)}
                  alt="User profile"
                />
              </div>
            </div>
          )}
        >
          <Dropdown.Item
            className={classes.dropdownFirstCard}
            onSelect={goToProfile}
          >
            <div className={classes.profile}>
              <div className={classes.dropdownThumbnail}>
                <img
                  style={{ width: "100%" }}
                  src={getProfileImage(user.profileImg, user.gender)}
                  alt="User profile"
                />
              </div>
            </div>
            <Info>
              <p>{`${user.username}`}</p>
            </Info>
          </Dropdown.Item>
          {navigationLinks.map((dLink: IDropdownMenuItem) => (
            <Dropdown.Item
              key={dLink.label}
              className={classes.dropdownLink}
              onSelect={dLink.action}
            >
              <img
                style={{ height: "16px", marginRight: "10px" }}
                src={dLink.icon}
                alt={dLink.iconAlt}
              />

              {dLink.label}
            </Dropdown.Item>
          ))}

          {/* <Dropdown.Item>
            {" "}
            <ToggleContainer>
              <Toggle
                checked={theme === "light"}
                aria-label="Switch"
                onChange={switchTheme}
                checkedChildren="light"
                unCheckedChildren="dark"
              />
            </ToggleContainer>{" "}
          </Dropdown.Item> */}
        </Dropdown>
      ) : null}
    </div>
  );
}

export default NavBarProfileDropdown;
