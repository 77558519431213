import React from "react";
import styled from "styled-components";

interface IProps {
  duration: number; // length of animation (milliseconds)
  delay: number; // length to delay animation start (milliseconds)
  children: any;
}

const Container = styled.div<{ duration: number; delay: number }>`
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: fadeIn;
  opacity: 0;
  animation-timing-function: ease-in-out;
  animation-duration: ${(props) => props.duration}ms;
  animation-delay: ${(props) => props.delay}ms;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
/**
 * Fades in children after a delay
 * @param props
 * @returns
 */
function Fade(props: IProps) {
  const { duration, delay, children } = props;

  return (
    <Container duration={duration} delay={delay}>
      {children}
    </Container>
  );
}

export default Fade;
