import { useState, useEffect } from "react";
import {
  getNotificationTotal,
  getUserNotifications,
  getNotificationPage,
  getNotificationLimit,
  getNotificationTotalPages,
  getNotificationHasNextPage,
  getInitFetch,
  getUserViewNotificationAlert,
} from "store/slices/userNotifications";
import {
  dispatchMarkNotificationAsRead,
  dispatchSetUserNotifications,
  dispatchMarkAllAsRead,
} from "store/dispatcher";
import { serverErrorHandler } from "../../../services/server-error.service";
import getNotifications from "../api/getNotifications";
import { useAppSelector } from "../../../store/hooks";
import markNotificationAsRead from "../api/markAsRead";
import markAllNotificationAsRead from "../api/markAllNotificationsAsRead";

/**
 * Hook to manage user notifications
 * @returns
 */
export default function useNotifications() {
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState<boolean>(false);
  const viewNotificationAlert = useAppSelector(getUserViewNotificationAlert);

  const notifications = useAppSelector(getUserNotifications);
  const page = useAppSelector(getNotificationPage);
  const limit = useAppSelector(getNotificationLimit);
  const totalPages = useAppSelector(getNotificationTotalPages);
  const total = useAppSelector(getNotificationTotal);
  const hasNextPage = useAppSelector(getNotificationHasNextPage);
  const initFetch = useAppSelector(getInitFetch);

  /**
   * On Mount, make a request for user notifications
   */
  useEffect(() => {
    async function fetch() {
      try {
        const response = await getNotifications(page, limit);
        const y = [...notifications, ...response.docs];
        dispatchSetUserNotifications({
          notifications: y,
          totalPages: response.totalPages,
          totalDocs: response.totalDocs,
          page: response.page,
          limit: response.limit,
          hasNextPage: response.hasNextPage,
          totalUnread: response.totalUnread,
          viewNotificationAlert,
        });
        setIsDone(true);
      } catch (e) {
        serverErrorHandler(e);
        setIsDone(true);
      }
    }

    if (notifications.length === 0 && isDone === false && initFetch === false) {
      fetch();
    } else {
      setIsDone(true);
    }
  }, [notifications, isDone, initFetch]);

  /**
   * Checks if notification is read
   * @param list list of all notifications
   * @param id id of notification to check
   * @returns true if the notification is read
   */
  const isNotificationRead = (id: string) => {
    let flag = false;
    const i = notifications.map((n) => n._id).indexOf(id);

    if (i > -1) {
      flag = notifications[i].read;
    }
    return flag;
  };

  /**
   * Makes api request to mark notification as read
   * @param id
   */
  const markAsRead = async (id: string) => {
    // only run if notification is not read
    if (!isNotificationRead(id)) {
      try {
        setIsLoading(true);
        // make server request
        await markNotificationAsRead(id);
        // update in redux store
        dispatchMarkNotificationAsRead(id);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        serverErrorHandler(e);
      }
    }
  };

  /**
   * Makes api request to mark all notifications as read
   * @param id
   */
  const markAllAsRead = async () => {
    try {
      setIsMarkingAllAsRead(true);
      await markAllNotificationAsRead();
      setIsMarkingAllAsRead(false);
      dispatchMarkAllAsRead();
    } catch (e) {
      setIsMarkingAllAsRead(false);
      serverErrorHandler(e);
    }
  };

  /**
   * Makes request for the next page aka
   * more notifications. These are appended to existing
   * notification list in redux store
   */
  const fetchMore = async () => {
    try {
      setIsFetching(true);
      // make api req
      const response = await getNotifications(page + 1, limit);
      // combine into single list
      const y = [...notifications, ...response.docs];
      // update store
      dispatchSetUserNotifications({
        notifications: y.filter((value: any, index: any, self: any) => {
          const i = self.findIndex((t: any) => t._id === value._id);
          return index === i;
        }),
        totalPages: response.totalPages,
        totalDocs: response.totalDocs,
        page: response.page,
        limit: response.limit,
        hasNextPage: response.hasNextPage,
        totalUnread: response.totalUnread,
        viewNotificationAlert,
      });
      setIsFetching(true);
    } catch (e) {
      serverErrorHandler(e);
      setIsFetching(true);
    }
  };

  const extractNotificationData = () =>
    notifications.filter((n) => {
      if (n.projectCommentNotification) {
        return n.projectCommentNotification.comment;
      }

      if (n.teamProjectCommentNotification) {
        return n.teamProjectCommentNotification.comment;
      }

      if (n.teamInvitationNotification) {
        return n.teamInvitationNotification.invitation;
      }
      if (n.projectLikeNotification) {
        return n.projectLikeNotification.like;
      }

      return null;
    });

  return {
    isDone,
    notifications: extractNotificationData(),
    totalPages,
    total,
    hasNextPage,
    isLoading,
    isMarkingAllAsRead,
    isFetching,
    markAsRead,
    fetchMore,
    isNotificationRead,
    markAllAsRead,
  };
}
