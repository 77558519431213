import { momentDateDetailed } from "../services/moment.service";

const isEnabled = process.env.REACT_APP_ENABLE_ANALYTICS;

/**
 * This hook will track analytics on the platform
 * @returns
 */
export default function useAnalytics() {
  /**
   * Tracks sign up event
   * @param organization - users organization
   * @param gender - users gender
   * @param dob - date of birth
   */
  function trackUserSignUp() {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "sign_up");
      }
    }
  }

  /**
   * tracks user sign in
   */
  function trackUserSignIn(email: string) {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "sign_in", {
          email,
          date: momentDateDetailed(new Date()),
        });
      }
    }
  }

  /**
   * tracks when user send forgot password code
   */
  function trackUserForgotPassword() {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "forgot_password");
      }
    }
  }

  /**
   * tracks when user resets their password
   */
  function trackUserResetPassword() {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "reset_password");
      }
    }
  }

  /**
   * Tracks when user clicks on view project details
   */
  function trackViewProjectDetails() {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "view_project_details");
      }
    }
  }

  function trackEditProject() {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "edit_project");
      }
    }
  }

  function trackProjectFullPage() {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "view_full_page_project");
      }
    }
  }

  function trackCreateTeam() {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "create_team");
      }
    }
  }

  function trackCreateProject() {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "create_project");
      }
    }
  }

  function trackCreateTeamProject() {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "create_team_project");
      }
    }
  }

  function trackEditTeamProject() {
    if (isEnabled) {
      if (typeof window.gtag !== "undefined") {
        window.gtag("event", "edit_team_project");
      }
    }
  }

  return {
    trackUserSignIn,
    trackUserSignUp,
    trackUserForgotPassword,
    trackViewProjectDetails,
    trackProjectFullPage,
    trackEditProject,
    trackCreateTeam,
    trackCreateProject,
    trackCreateTeamProject,
    trackEditTeamProject,
    trackUserResetPassword,
  };
}
