import { makeGetRequest } from "../../../config/axios.config";

/**
 * Marks all notifications as read
 */
export default async function markAllNotificationAsRead(): Promise<string> {
  const url = "hustlencode/notifications/read-all";
  const response = await makeGetRequest(url);
  return response.data.message;
}
