import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  forwardRef,
  KeyboardEventHandler,
  useRef,
  useState,
} from "react";
import { Input, InputGroup } from "rsuite";
import { useAppSelector } from "store/hooks";
import { getColorTheme } from "store/slices/userSessionSlice";
import { NavLink, useSearchParams } from "react-router-dom";
import useUserSearch from "features/search/hooks/useNavSearchUsers";
import useOutsideClick from "hooks/useOutsideClick";
import useAppNavigation from "hooks/useAppNavigation";
import classes from "./NavbarSearch.module.scss";
import NavBarSearchResultCard from "../NavBarSearchResultCard/NavBarSearchResultCard";

const WIDTH = "500px";

const NavbarSearch = forwardRef((props: any, ref: any) => {
  const [searchParams] = useSearchParams();

  // grab search value from the url
  const urlSearchValue = searchParams.get("q");

  // tracks if the search results container show be shown/hidden
  const [showResults, setShowResults] = useState<boolean>(false);

  // get app navigation
  const { getUserSearchUrl, goToUserSearch } = useAppNavigation();

  // get user search
  const { isFetching, results, searchValue, onSearch, clearState } =
    useUserSearch(urlSearchValue || "", 5);

  const resultsRef: any = useRef(null);

  // get current theme value from store
  const theme = useAppSelector(getColorTheme);

  // listen for clicks outside results container
  // hide container when clicks outside happen
  useOutsideClick(resultsRef, () => {
    setShowResults(false);
  });

  /**
   * Show user results
   */
  const searchClickHandler = () => {
    if (results.length > 0) {
      setShowResults(true);
    }
  };

  const resetState = () => {
    // hide results container
    setShowResults(false);

    // clear user search
    clearState();

    // clear the input field search value
    if (ref && ref.current) {
      // eslint-disable-next-line no-param-reassign
      ref.current.value = "";
    }
  };

  /**
   * Updates the the show/hide state of the search results
   * container. If a value is present, the container is shown
   * and a async req is made for results
   * @param val Search value
   */
  const searchChangeHandler = (val: string) => {
    // perform search
    onSearch(val);

    if (val.trim() !== "") {
      // show result container
      setShowResults(true);
    } else {
      // hide results container
      setShowResults(false);
    }
  };

  /**
   * Listens for keydown on search field
   * If the key matches "Enter",
   * navigate the user to the search results page
   * @param e
   */
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      goToUserSearch(searchValue);
      setShowResults(false);
      ref.current.blur();
    }
  };

  /**
   * Listens for search focus
   * Displays current search results if the
   * search query value is not empty
   */
  const onFocus = () => {
    if (urlSearchValue && urlSearchValue !== "") {
      setShowResults(true);
    }
  };

  /**
   * Renders the container that holds the search results
   * @returns
   */
  const renderResultContainer = () => (
    <div
      style={{ width: WIDTH + 40 }}
      className={classes.results}
      ref={resultsRef}
    >
      {/* Top 5 Search results for relevance in your search */}
      {isFetching ? (
        <div className={classes.searching}>
          <p>Searching users....</p>
        </div>
      ) : (
        <div className={classes.link}>
          <p>
            Top {results.length} result
            {`${results.length > 1 || results.length === 0 ? "s" : ""}`} for
            &#34;
            {searchValue}
            &#34;
          </p>
        </div>
      )}

      {/* Render the top 5 results with nav link to the user profile */}
      {isFetching ? (
        <div className={classes.spinner}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        results.map((profile) => (
          <NavLink
            end
            key={profile.username}
            className={classes.searchResult}
            to={`/user/${profile._id}`}
            onClick={resetState}
            data-theme={theme}
          >
            <NavBarSearchResultCard profile={profile} />
          </NavLink>
        ))
      )}

      {/* Give the user the option to search for all profiles with the search value name */}
      {isFetching ? null : (
        <NavLink
          className={classes.searchAllContainer}
          to={getUserSearchUrl(searchValue)}
          onClick={() => {
            // hides search result window on navigate
            setShowResults(false);
          }}
          end
        >
          <div className={classes.iconSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <div className={classes.searchContent}>
            <p>
              Search for &#34;
              {searchValue}
              &#34;
            </p>
          </div>
        </NavLink>
      )}
    </div>
  );

  return (
    <div style={{ width: "50%", marginRight: "24px" }}>
      <div className={classes.inputGroup}>
        <InputGroup className={classes.group}>
          <InputGroup.Addon>
            <FontAwesomeIcon icon={faSearch} style={{ color: "gray" }} />
          </InputGroup.Addon>
          <Input
            ref={ref}
            onClick={searchClickHandler}
            onChange={searchChangeHandler}
            placeholder="Search users"
            onKeyDown={onKeyDown}
            value={searchValue}
            onFocus={onFocus}
          />
        </InputGroup>
      </div>

      {showResults && renderResultContainer()}
    </div>
  );
});

export default NavbarSearch;
