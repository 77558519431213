/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IUserEditorState {
  html: number;
  css: number;
  js: number;
  top: number;
  bottom: number;
  expand: boolean;
  fontSize: number;
}

const LOCAL_STORAGE_HTML_KEY = "hnc-editor-html-win-size";
const LOCAL_STORAGE_CSS_KEY = "hnc-editor-css-win-size";
const LOCAL_STORAGE_JS_KEY = "hnc-editor-js-win-size";
const LOCAL_STORAGE_TOP_KEY = "hnc-editor-top-win-size";
const LOCAL_STORAGE_BOTTOM_KEY = "hnc-editor-bottom-win-size";
const LOCAL_STORAGE_FONT_SIZE = "project-editor-size";

const html = localStorage[LOCAL_STORAGE_HTML_KEY]
  ? JSON.parse(localStorage[LOCAL_STORAGE_HTML_KEY])
  : null;

const css = localStorage[LOCAL_STORAGE_CSS_KEY]
  ? JSON.parse(localStorage[LOCAL_STORAGE_CSS_KEY])
  : null;

const js = localStorage[LOCAL_STORAGE_JS_KEY]
  ? JSON.parse(localStorage[LOCAL_STORAGE_JS_KEY])
  : null;

const top = localStorage[LOCAL_STORAGE_TOP_KEY]
  ? JSON.parse(localStorage[LOCAL_STORAGE_TOP_KEY])
  : null;

const bottom = localStorage[LOCAL_STORAGE_BOTTOM_KEY]
  ? JSON.parse(localStorage[LOCAL_STORAGE_BOTTOM_KEY])
  : null;

const fontSize = localStorage[LOCAL_STORAGE_FONT_SIZE]
  ? JSON.parse(localStorage[LOCAL_STORAGE_FONT_SIZE])
  : null;
// initial state for current user session
const initialState: IUserEditorState = {
  html,
  css,
  js,
  top,
  bottom,
  expand: false,
  fontSize,
};

export const userEditorSlice = createSlice({
  name: "userEditor",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateEditorFontSize: (state, action: PayloadAction<number>) => {
      state.fontSize = action.payload;

      localStorage[LOCAL_STORAGE_FONT_SIZE] = action.payload;
    },

    updateHorizontalSplitWindowSizes: (
      state,
      action: PayloadAction<{
        html: number;
        css: number;
        js: number;
      }>
    ) => {
      state.html = action.payload.html;
      state.css = action.payload.css;
      state.js = action.payload.js;

      localStorage[LOCAL_STORAGE_HTML_KEY] = action.payload.html;
      localStorage[LOCAL_STORAGE_CSS_KEY] = action.payload.css;
      localStorage[LOCAL_STORAGE_JS_KEY] = action.payload.js;
    },

    updateVerticalSplitWindowSizes: (
      state,
      action: PayloadAction<{
        top: number;
        bottom: number;
      }>
    ) => {
      state.html = action.payload.top;
      state.css = action.payload.bottom;

      localStorage[LOCAL_STORAGE_TOP_KEY] = action.payload.top;
      localStorage[LOCAL_STORAGE_BOTTOM_KEY] = action.payload.bottom;
    },

    expandHorizontalSplitWindow: (
      state,
      action: PayloadAction<"html" | "css" | "js">
    ) => {
      const win = action.payload;
      let sizes = {
        html: 0,
        css: 0,
        js: 0,
      };

      if (win === "html") {
        sizes = { html: 90, css: 5, js: 5 };
      } else if (win === "css") {
        sizes = { html: 5, css: 90, js: 5 };
      } else {
        sizes = { html: 5, css: 5, js: 90 };
      }

      state.html = sizes.html;
      state.css = sizes.css;
      state.js = sizes.js;
      state.expand = true;

      localStorage[LOCAL_STORAGE_HTML_KEY] = sizes.html;
      localStorage[LOCAL_STORAGE_CSS_KEY] = sizes.css;
      localStorage[LOCAL_STORAGE_JS_KEY] = sizes.js;
    },

    finishExpandSplitWindow: (state) => {
      state.expand = false;
    },
  },
});

export const {
  updateHorizontalSplitWindowSizes,
  expandHorizontalSplitWindow,
  updateVerticalSplitWindowSizes,
  finishExpandSplitWindow,
  updateEditorFontSize,
} = userEditorSlice.actions;

// return sizes for the horizontal editor splitter
export const getSplitWindowSizes = (state: RootState) => ({
  html: state.userEditor.html,
  css: state.userEditor.css,
  js: state.userEditor.js,
  top: state.userEditor.top,
  bottom: state.userEditor.bottom,
  expand: state.userEditor.expand,
});

export const getEditorFontSize = (state: RootState) =>
  state.userEditor.fontSize;

export default userEditorSlice.reducer;
