/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useMemo } from "react";
import { useSetState } from "react-use";
import { AppState } from "../interfaces/types";

// Initial state of the context
const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  mainTutorial: { run: false, tourActive: false },
  projectTutorial: { run: false, tourActive: false },
  intProjectTutorial: {
    run: false,
    tourActive: false,
  },
  settingsTutorial: { run: false, tourActive: false },
  updatingText: "",
  RGB: { R: 127.5, G: 127.5, B: 127.5 },
};
// Create the AppContext context object
export const AppContext = createContext({
  state: appState,
  setState: () => undefined,
});
AppContext.displayName = "AppContext";

// AppProvider component that provides the context value to its children
export function AppProvider(props: any) {
  // Use the useSetState hook to manage the state of the context
  const [state, setState] = useSetState(appState);

  // Use the useMemo hook to create a value object that will be provided to the context
  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state]
  );

  // Render the AppProvider component, providing the value object to the context
  return <AppContext.Provider value={value} {...props} />;
}

// Custom hook for getting the context value
export function useAppContext(): {
  setState: (
    patch: Partial<AppState> | ((previousState: AppState) => Partial<AppState>)
  ) => void;
  state: AppState;
} {
  // Use the useContext hook to get the context value
  const context = useContext(AppContext);

  // If the context value is not available, throw an error
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }

  // Return the context value
  return context;
}
