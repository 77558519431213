import React from "react";
import styled from "styled-components";

const Container = styled.div<{ isActive: boolean }>`
  margin-left: auto;
  width: 55px;
  height: 55px;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  transition: all 0.5s ease;
`;

const BurgerIcon = styled.div<{ isActive: boolean; color: string | undefined }>`
  transform: ${(props) =>
    props.isActive ? "scale(0.7) rotate(45deg)" : "scale(0.7)"};
  transition: ${(props) =>
    props.isActive ? "all 0.2s ease 0.05s" : "all 0.5s ease"};

  div {
    height: 5px;
    border-radius: 2px;
    margin: 10px;
    transition: all 0.65s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 50px;
    background: ${(props) =>
      props.color ? props.color : "var(--color-white)"};
  }

  div:first-child {
    transform: ${(props) =>
      props.isActive
        ? "translateY(13px) rotate(0deg)"
        : "translateY(0px) rotate(0deg)"};
  }

  div:nth-child(2) {
    opacity: ${(props) => (props.isActive ? "0" : "1")};
  }

  div:last-child {
    transform: ${(props) =>
      props.isActive
        ? "translateY(-18px) rotate(90deg)"
        : "translateY(0px) rotate(0deg)"};
  }
`;

export default function MobileMenuTrigger({
  isActive,
  setIsActive,
  color,
}: {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
  color?: string | undefined;
}) {
  return (
    <Container
      isActive={isActive}
      onClick={() => {
        setIsActive(!isActive);
      }}
    >
      <BurgerIcon color={color} isActive={isActive}>
        <div />
        <div />
        <div />
      </BurgerIcon>
    </Container>
  );
}

MobileMenuTrigger.defaultProps = {
  color: null,
};
