import React from "react";
import ProfileImage from "components/Elements/ProfileImage/ProfileImage";
import useNotifications from "features/notifications/hooks/useNotifications";
import EllipsisMenu from "components/Elements/Menus/EllipsisMenu";
import MenuItem from "components/Elements/Menus/MenuItem";
import FromNowDate from "components/Elements/Date/FromNowDate";
import useAppNavigation from "hooks/useAppNavigation";
import { NotificationCard, RedDot } from "./styled/index";
import { Notification } from "../../types/index";

export interface ITeamProjectCommentNotificationCardProps {
  notification: Notification;
  close: () => void;
}

/**
 * Renders the notification content for team invitations
 * @param props
 * @returns
 */
export default function TeamProjectCommentNotificationCard(
  props: ITeamProjectCommentNotificationCardProps
) {
  const { notification, close } = props;

  const { teamProjectCommentNotification, creator } = notification;
  const { comment } = teamProjectCommentNotification;

  const { markAsRead, isLoading, isNotificationRead } = useNotifications();

  const { navigateToTeamProjectDetailsPage } = useAppNavigation();

  // appends notification id before calling api
  const onMarkAsRead = () => markAsRead(notification._id);

  const menu = [];

  // add mark as read menu item
  if (!isNotificationRead(notification._id)) {
    menu.push(
      <MenuItem key="Mark as read" action={onMarkAsRead} label="Mark as read" />
    );
  }

  /**
   * Navigates to project details page and
   * scrolls to comment location
   */
  const nav = () => {
    onMarkAsRead();
    navigateToTeamProjectDetailsPage(comment.post._id, comment._id);
    close();
  };

  return (
    <NotificationCard.Container>
      <NotificationCard.ContainerLeft onClick={nav}>
        {!notification.read && <RedDot />}
        <NotificationCard.ImageContainer>
          <ProfileImage
            url={notification.creator.profileImg}
            gender={notification.creator.gender}
          />
        </NotificationCard.ImageContainer>
      </NotificationCard.ContainerLeft>

      <NotificationCard.ContainerCenter onClick={nav}>
        <NotificationCard.TitleContainer>
          <p>
            <strong>
              <span>{creator.username}</span> commented on your team project:
            </strong>
          </p>
        </NotificationCard.TitleContainer>
        <NotificationCard.BodyContainer>
          <NotificationCard.CommentBody>
            {comment.message}
          </NotificationCard.CommentBody>
        </NotificationCard.BodyContainer>
      </NotificationCard.ContainerCenter>

      <NotificationCard.ContainerRight>
        {notification.read ? (
          <div style={{ height: 40, width: 40 }} />
        ) : (
          <EllipsisMenu
            className="notification-menu"
            menuOptions={menu}
            isLoading={isLoading}
            placement="bottomEnd"
            width={40}
            height={40}
            icon="horizontal"
          />
        )}

        <NotificationCard.Timestamp>
          <FromNowDate date={notification.createdOn} />
        </NotificationCard.Timestamp>
      </NotificationCard.ContainerRight>
    </NotificationCard.Container>
  );
}
