import React from "react";
import ProfileImage from "components/Elements/ProfileImage/ProfileImage";
import styled from "styled-components";
import { IHncUserSearchResult } from "types/user.interface";

const ImageContainer = styled.div`
  flex: 0 0 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    text-transform: capitalize;
    margin: 0px;
  }

  p:first-child {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-black);
  }

  p:last-child {
    font-weight: 400;
    font-size: 13px;
    color: var(--color-nav-blue);
    text-transform: none;
  }
`;

type NavBarSearchResultCardProps = {
  profile: IHncUserSearchResult;
};

/**
 * Renders search result details
 * @param props
 * @returns
 */
export default function NavBarSearchResultCard(
  props: NavBarSearchResultCardProps
) {
  const { profile } = props;

  return (
    <>
      <ImageContainer>
        <ProfileImage url={profile.profileImg} gender={profile.gender} />
      </ImageContainer>

      <Details>
        <p>{profile.name}</p>
        <p>{profile.username}</p>
      </Details>
    </>
  );
}
