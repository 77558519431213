import { makePutRequest } from "config/axios.config";

/**
 * Marks notification as read
 */
export default async function markNotificationAsRead(
  id: string
): Promise<Notification> {
  const url = `hustlencode/notifications/read?id=${id}`;
  const response = await makePutRequest(url, {});
  return response.data.payload;
}
