import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { KeyboardEventHandler, useRef, useState } from "react";
import { Input, InputGroup } from "rsuite";
import { useAppSelector } from "store/hooks";
import { getColorTheme } from "store/slices/userSessionSlice";
import { NavLink, useSearchParams } from "react-router-dom";
import useUserSearch from "features/search/hooks/useNavSearchUsers";
import useAppNavigation from "hooks/useAppNavigation";
import styled from "styled-components";
import { IHncUserSearchResult } from "../../../types/user.interface";
import NavBarSearchResultCard from "../NavBarSearchResultCard/NavBarSearchResultCard";

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 10px;
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
  padding-bottom: 10px;
  box-shadow: rgba(28, 28, 28, 0.2) 0px 2px 4px 0px;
  border: solid 1px rgb(237, 239, 241);
`;

const SearchResult = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 7px 16px;
  gap: 10px;

  &:hover {
    cursor: pointer;
    background-color: var(--color-black);

    p:first-child {
      color: var(--color-white);
    }
  }
`;

const SearchIcon = styled.div`
  font-size: 15px;
  padding: 0px 15px 0px 0px;
  color: black;
`;

const SearchAll = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 16px;
  gap: 3px;

  p {
    font-size: 15px;
    color: black;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(182, 181, 181, 0.9);
  }
`;

interface ISearchResultsProps {
  isFetching: boolean;
  results: IHncUserSearchResult[];
  searchValue: string;
  hide: () => void;
}

function SearchResults(props: ISearchResultsProps) {
  const { isFetching, results, searchValue, hide } = props;
  // get current theme value from store
  const theme = useAppSelector(getColorTheme);

  // get app navigation
  const { navigateToSearchResults } = useAppNavigation();

  if (isFetching) {
    <ResultsContainer data-theme={theme}>
      <div>
        <p>Searching users....</p>
      </div>

      <div>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    </ResultsContainer>;
  }

  if (results.length === 0) {
    return (
      <ResultsContainer data-theme={theme}>
        <div>
          <p>
            No results for &#34;
            {searchValue}
            &#34;
          </p>
        </div>
      </ResultsContainer>
    );
  }

  return (
    <ResultsContainer data-theme={theme}>
      {/* Top 5 Search results for relevance in your search */}
      <div>
        <p>
          Top {results.length} result
          {`${results.length > 1 || results.length === 0 ? "s" : ""}`} for &#34;
          {searchValue}
          &#34;
        </p>
      </div>

      {/* Render the top 5 results with nav link to the user profile */}
      {results.map((profile) => (
        <SearchResult
          end
          key={profile.username}
          onClick={() => hide()}
          to={`/user/${profile._id}`}
          data-theme={theme}
        >
          <NavBarSearchResultCard profile={profile} />
        </SearchResult>
      ))}

      {/* Give the user the option to search for all profiles with the search value name */}
      <SearchAll>
        <SearchIcon>
          <FontAwesomeIcon icon={faSearch} />
        </SearchIcon>
        <button
          type="button"
          onClick={() => {
            navigateToSearchResults(searchValue);
            hide();
          }}
        >
          <p>
            Search for &#34;
            {searchValue}
            &#34;
          </p>
        </button>
      </SearchAll>
    </ResultsContainer>
  );
}

export default function MobileSearch({ hide }: { hide: () => void }) {
  const [searchParams] = useSearchParams();

  // grab search value from the url
  const urlSearchValue = searchParams.get("q");

  // tracks if the search results container show be shown/hidden
  const [showResults, setShowResults] = useState<boolean>(false);

  // get app navigation
  const { goToUserSearch } = useAppNavigation();

  // get user search
  const { isFetching, results, searchValue, onSearch } = useUserSearch(
    urlSearchValue || "",
    5
  );

  const ref: any = useRef(null);

  // get current theme value from store
  const theme = useAppSelector(getColorTheme);

  /**
   * Show user results
   */
  const searchClickHandler = () => {
    if (results.length > 0) {
      setShowResults(true);
    }
  };

  /**
   * Updates the the show/hide state of the search results
   * container. If a value is present, the container is shown
   * and a async req is made for results
   * @param val Search value
   */
  const searchChangeHandler = (val: string) => {
    // perform search
    onSearch(val);

    if (val.trim() !== "") {
      // show result container
      setShowResults(true);
    } else {
      // hide results container
      setShowResults(false);
    }
  };

  /**
   * Listens for keydown on search field
   * If the key matches "Enter",
   * navigate the user to the search results page
   * @param e
   */
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      goToUserSearch(searchValue);
      setShowResults(false);
      ref.current.blur();
    }
  };

  /**
   * Listens for search focus
   * Displays current search results if the
   * search query value is not empty
   */
  const onFocus = () => {
    if (urlSearchValue && urlSearchValue !== "") {
      setShowResults(true);
    }
  };

  return (
    <div data-theme={theme}>
      <InputGroup>
        <InputGroup.Addon>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Addon>
        <Input
          ref={ref}
          onClick={searchClickHandler}
          onChange={searchChangeHandler}
          placeholder="Search users"
          onKeyDown={onKeyDown}
          value={searchValue}
          onFocus={onFocus}
        />
      </InputGroup>

      {/* only show search results if search value is not empty */}
      {showResults && (
        <SearchResults
          searchValue={searchValue}
          results={results}
          isFetching={isFetching}
          hide={hide}
        />
      )}
    </div>
  );
}
