import NavBar from "components/Navigation/NavBar/NavBar";
import React, { useRef } from "react";
import { IUserSession } from "types/user.interface";
import useCheckTutorialActive from "features/onboarding/hooks/useCheckTutorialActive";

type MainLayoutProps = {
  children: ({ searchRef }: { searchRef: any }) => React.ReactNode;
  user: IUserSession;
};

export default function MainLayout({ children, user }: MainLayoutProps) {
  // ref for search bar

  const { checkIfOneActive } = useCheckTutorialActive();
  const searchRef: any = useRef(null);
  return (
    <>
      {!checkIfOneActive() && ( // If any Onboarding is active, hide the navbar
        <NavBar user={user} ref={searchRef} />
      )}
      {children({ searchRef })}
    </>
  );
}
