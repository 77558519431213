import React from "react";
import ProfileImage from "components/Elements/ProfileImage/ProfileImage";
import useNotifications from "features/notifications/hooks/useNotifications";
import EllipsisMenu from "components/Elements/Menus/EllipsisMenu";
import MenuItem from "components/Elements/Menus/MenuItem";
import FromNowDate from "components/Elements/Date/FromNowDate";
import useAppNavigation from "hooks/useAppNavigation";
import { NotificationCard, RedDot } from "./styled/index";
import { Notification } from "../../types/index";

export interface IProjectLikeNotificationCardProps {
  notification: Notification;
  close: () => void;
}

/**
 * Renders the notification content for project likes
 * @param props
 * @returns
 */
export default function ProjectLikeNotificationCard(
  props: IProjectLikeNotificationCardProps
) {
  const { notification, close } = props;
  const { projectLikeNotification, creator } = notification;
  const { like } = projectLikeNotification;

  // Notifications hook
  const { markAsRead, isLoading, isNotificationRead } = useNotifications();

  // Navigation hook
  const { navigateToProjectDetailsPage } = useAppNavigation();

  // appends notification id before calling api
  const onMarkAsRead = () => markAsRead(notification._id);

  const menu = [];

  // add mark as read menu item
  if (!isNotificationRead(notification._id)) {
    menu.push(
      <MenuItem key="Mark as read" action={onMarkAsRead} label="Mark as read" />
    );
  }

  /**
   * Navigates to project details page that received the like
   */
  const nav = () => {
    onMarkAsRead();
    navigateToProjectDetailsPage(like.postId._id);
    close();
  };

  return (
    <NotificationCard.Container>
      <NotificationCard.ContainerLeft onClick={nav}>
        {!notification.read && <RedDot />}
        <NotificationCard.ImageContainer>
          <ProfileImage
            url={notification.creator.profileImg}
            gender={notification.creator.gender}
          />
        </NotificationCard.ImageContainer>
      </NotificationCard.ContainerLeft>

      <NotificationCard.ContainerCenter onClick={nav}>
        <NotificationCard.TitleContainer>
          <p>
            <strong>
              <span>{creator.username}</span> Liked your project:
            </strong>
          </p>
        </NotificationCard.TitleContainer>
        <NotificationCard.BodyContainer>
          <NotificationCard.CommentBody style={{ textAlign: "center" }}>
            Click to view project
          </NotificationCard.CommentBody>
        </NotificationCard.BodyContainer>
      </NotificationCard.ContainerCenter>

      <NotificationCard.ContainerRight>
        {notification.read ? (
          <div style={{ height: 40, width: 40 }} />
        ) : (
          <EllipsisMenu
            className="notification-menu"
            menuOptions={menu}
            isLoading={isLoading}
            placement="bottomEnd"
            width={40}
            height={40}
            icon="horizontal"
          />
        )}

        <NotificationCard.Timestamp>
          <FromNowDate date={notification.createdOn} />
        </NotificationCard.Timestamp>
      </NotificationCard.ContainerRight>
    </NotificationCard.Container>
  );
}
