import { makeGetRequest } from "../../../config/axios.config";

/**
 * Makes request to hide the users notification alert
 * @returns
 */
export default async function hideNotificationAlert() {
  const url = "hustlencode/notifications/hide-alert";
  const response = await makeGetRequest(url);
  return response.data.payload;
}
