import React from "react";
import {
  faEllipsisH,
  faEllipsisV,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown } from "rsuite";
import styled from "styled-components";

interface IProps {
  id?: string;
  menuOptions: JSX.Element[];
  isLoading: boolean;
  placement: DropdownPlacement;
  className?: any;
  width: number;
  height: number;
  icon?: "vertical" | "horizontal";
}

const Btn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  background-color: transparent;

  &:hover {
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.35);
    box-shadow: none !important;
    background-color: transparent;
  }

  svg {
    color: var(--color-black);
    font-size: 16px;
  }

  .dropdown {
    z-index: 9;

    li {
      padding: 15px 24px;

      @media (min-width: 992px) {
        padding: 14px 24px;
      }

      @media (min-width: 992px) {
        padding: 12px 24px;
      }
    }
  }
`;

const Container = styled.div<{ width: number; height: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
/**
 * Renders the menu for the container. Each item
 * in the items list is rendered as a menu item
 * @param props
 * @returns
 */
function EllipsisMenu(props: IProps) {
  const {
    icon = "vertical",
    id,
    width,
    height,
    menuOptions,
    isLoading,
    placement,
    className,
  } = props;

  const toggleBtn = (btnProps: any, ref: any) => (
    <Btn
      aria-label="Three Dots Button"
      className={className}
      disabled={isLoading}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...btnProps}
      ref={ref}
    >
      <FontAwesomeIcon icon={icon === "vertical" ? faEllipsisV : faEllipsisH} />
    </Btn>
  );

  return (
    <Container width={width} height={height}>
      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <Dropdown
          id={id}
          style={{ width: "100%", height: "100%" }}
          trigger="click"
          placement={placement}
          renderToggle={toggleBtn}
        >
          {menuOptions}
        </Dropdown>
      )}
    </Container>
  );
}

export default EllipsisMenu;

EllipsisMenu.defaultProps = {
  id: "",
  className: "",
  icon: "vertical",
};
