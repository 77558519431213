/* eslint-disable no-unused-vars */
import React from "react";
import { getUser } from "../store/slices/userSessionSlice";
import PageLoader from "../components/Elements/Loaders/PageLoader/PageLoader";
import { Theme } from "../types/index";
import { useAppSelector } from "../store/hooks";
import { IUserSession } from "../types/user.interface";

/**
 * Renders children if user profile is not null
 * otherwise shows a spinner
 * @param param0
 * @returns
 */
export default function UserProfileProvider({
  children,
  theme,
}: {
  children: ({ user }: { user: IUserSession }) => React.ReactNode;
  theme: Theme;
}) {
  // fetch profile data from store
  const user = useAppSelector(getUser);

  return (
    <>
      {" "}
      {user !== null ? (
        children({ user })
      ) : (
        <PageLoader isVisible fullscreen={false} theme={theme} />
      )}
    </>
  );
}
