/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project } from "../../features/projects/types/index";
import { TeamProject } from "../../features/team-projects/types/hustlencode-team-post.interface";
import { RootState } from "../store";

export interface IUserFeed {
  projects: Project[] | TeamProject[];
  scrollPos: number;
  type: string;
  page: number;
  totalDocs: number;
  hasMore: boolean;
  totalPages: number;
  activeProjectId: string;
}

export interface IProfileFeed {
  projects: Project[];
  scrollPos: number;
  page: number;
  totalDocs: number;
  hasMore: boolean;
  totalPages: number;
  activeProjectId: string;
}

export interface ITeamFeed {
  projects: TeamProject[];
  scrollPos: number;
  page: number;
  totalDocs: number;
  hasMore: boolean;
  totalPages: number;
  activeProjectId: string;
}

export interface IUserFeedState {
  feed: IUserFeed | null;
  profileFeed: IProfileFeed | null;
  teamFeed: ITeamFeed | null;
}

export interface ISetUserFeedPayload {
  projects: Project[] | TeamProject[];
  scrollPos: number;
  type: string;
  page: number;
  totalDocs: number;
  hasMore: boolean;
  totalPages: number;
  activeProjectId: string;
}

export interface ISetProfileFeedPayload {
  projects: Project[];
  scrollPos: number;
  page: number;
  totalDocs: number;
  hasMore: boolean;
  totalPages: number;
  activeProjectId: string;
}

export interface ISetTeamFeedPayload {
  projects: TeamProject[];
  scrollPos: number;
  page: number;
  totalDocs: number;
  hasMore: boolean;
  totalPages: number;
  activeProjectId: string;
}

// initial state for current user session
const initialState: IUserFeedState = {
  feed: null,
  profileFeed: null,
  teamFeed: null,
};

export const userFeedSlice = createSlice({
  name: "userFeed",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // sets general feed
    setFeed: (state, action: PayloadAction<ISetUserFeedPayload>) => {
      state.feed = action.payload;
    },
    setProfileFeed: (state, action: PayloadAction<ISetProfileFeedPayload>) => {
      state.profileFeed = action.payload;
    },
    setTeamFeed: (state, action: PayloadAction<ISetTeamFeedPayload>) => {
      state.teamFeed = action.payload;
    },
    // clears user feed
    clearFeed: (state) => {
      state.feed = null;
      state.teamFeed = null;
      state.profileFeed = null;
    },
  },
  extraReducers(builder) {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setFeed, setTeamFeed, setProfileFeed, clearFeed } =
  userFeedSlice.actions;

export const getUserFeed = (state: RootState) => state.userFeed.feed;
export const getProfileFeed = (state: RootState) => state.userFeed.profileFeed;
export const getTeamFeed = (state: RootState) => state.userFeed.teamFeed;

export default userFeedSlice.reducer;
