import React from "react";
import { getProfileImage } from "util/profile.util";
import { useInView } from "react-intersection-observer";

interface IProps {
  url?: string | null;
  gender?: Gender;
}

/**
 * Renders hustlencode user's profile image
 * If the user has no profile image,
 * the user's gender is used to render a default profile
 * image
 */
function ProfileImage(props: IProps) {
  const { url, gender } = props;

  // options for intersection observer - listen for container to come into view
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      {inView && (
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={getProfileImage(url, gender)}
          alt="user profile"
        />
      )}
    </div>
  );
}

export default ProfileImage;

ProfileImage.defaultProps = {
  url: null,
  gender: "unspecified",
};
