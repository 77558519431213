/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  hncColorTheme,
  hncStoredEmailStr,
  hncUserSession,
  prevPathStorageKey,
} from "../../constants/localstorage.constants";
import { IUserSession } from "../../types/user.interface";
import getTheme from "../../util/theme.util";
import { RootState } from "../store";

export interface IUserSessionState {
  user: IUserSession | null;
  storedEmailed: string;
  isLoadingApp: boolean;
  previousPath: string;
  history: string[];
  theme: ColorTheme; // add theme to our model - we specify the only two valid values
  activeSession: boolean;
  isDraggingSplitter: boolean;
  pageRedirect: null | string;
}

// initial state for current user session
const initialState: IUserSessionState = {
  isDraggingSplitter: false,
  user: null, // user profile
  storedEmailed: localStorage[hncStoredEmailStr] || "", // holds remember me email
  isLoadingApp: true, // tracks if the app doing initial load
  previousPath: "/",
  history: [],
  theme: getTheme(), // get local storage value for theme
  activeSession: !!localStorage[hncUserSession],
  pageRedirect: null,
};

export const userSessionSlice = createSlice({
  name: "userSession",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // stores login email in storage and redux store
    setStoredEmail: (state, action: PayloadAction<string>) => {
      state.storedEmailed = action.payload;
      localStorage[hncStoredEmailStr] = action.payload;
    },
    // clear login email from storage and redux store
    clearStoredEmail: (state) => {
      localStorage.removeItem(hncStoredEmailStr);
      state.storedEmailed = "";
    },
    // clear redux store of user data
    logout: (state) => {
      localStorage.removeItem(hncUserSession);
      localStorage[prevPathStorageKey] = "/"; // reset prev path
      state.user = null; // reset to init state
      state.isLoadingApp = true; // reset to init state
      state.previousPath = "/"; // reset to init state
      state.history = []; // reset to init state
      state.activeSession = false;
    },
    // updates loading state for our app - app is loading user session data is being requested
    setIsAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingApp = action.payload;
    },
    setIsActiveSession: (state, action: PayloadAction<boolean>) => {
      state.activeSession = action.payload;
    },
    // updates the previous path
    setPreviousPath: (state, action: PayloadAction<string>) => {
      state.history.push(action.payload);

      // only update if we have more than one url in the history
      // this indicates that the user is navigating from another page within
      // the app
      if (state.history.length > 1) {
        state.previousPath = state.history[state.history.length - 2];
      }
    },
    // updates user session data
    setUser: (state, action: PayloadAction<IUserSession>) => {
      if (action.payload) {
        localStorage[hncUserSession] = action.payload._id;
        state.activeSession = true;
        state.user = action.payload;
      }
    },

    // updates user color theme
    setTheme: (state, action: PayloadAction<ColorTheme>) => {
      localStorage[hncColorTheme] = action.payload;
      state.theme = action.payload;
    },

    setIsDragging: (state) => {
      state.isDraggingSplitter = true;
    },

    setIsNotDragging: (state) => {
      state.isDraggingSplitter = false;
    },

    setPageRedirectUrl: (state, action: PayloadAction<string>) => {
      state.pageRedirect = action.payload;
    },

    setClearPageRedirect: (state) => {
      state.pageRedirect = null;
    },
  },
  extraReducers(builder) {
    builder.addCase("LOGOUT", (state) => {
      localStorage.removeItem(hncUserSession);
      localStorage[prevPathStorageKey] = "/"; // reset prev path
      Object.assign(state, initialState);
    });
  },
});

export const {
  setTheme,
  setIsActiveSession,
  setIsAppLoading,
  setStoredEmail,
  clearStoredEmail,
  logout,
  setPreviousPath,
  setUser,
  setIsDragging,
  setIsNotDragging,
  setPageRedirectUrl,
  setClearPageRedirect,
} = userSessionSlice.actions;

// accessor for admin user
export const getUser = (state: RootState) => state.userSession.user;

export const getIsDragging = (state: RootState) =>
  state.userSession.isDraggingSplitter;

export const getActiveSession = (state: RootState) =>
  state.userSession.activeSession;

// get stored email
export const getEmail = (state: RootState) => state.userSession.storedEmailed;

// get previous path
export const getPreviousPath = (state: RootState) =>
  state.userSession.previousPath;

// gets navigation history
export const getHistory = (state: RootState) => state.userSession.history;

// get loader
export const getIsLoadingApp = (state: RootState) =>
  state.userSession.isLoadingApp;

// get color theme
export const getColorTheme = (state: RootState) => state.userSession.theme;

// get page redirect
export const getPageRedirect = (state: RootState) =>
  state.userSession.pageRedirect;

export default userSessionSlice.reducer;
