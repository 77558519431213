// import ProfileImage from "components/Elements/ProfileImage/ProfileImage";
import React from "react";
import useEnterProjectPageTutorial from "features/onboarding/hooks/useEnterProjectsPageTutorial";
import classes from "./CreatePostButton.module.scss";

/**
 * This component renders a box that users
 * can click on to navigate to the create post
 * page
 * @returns
 */

export default function CreatePostButton({ id }: { id?: string }) {
  const { HandleNavigate } = useEnterProjectPageTutorial();
  return (
    <button
      type="button"
      id={id}
      className={classes.header}
      onClick={() => {
        HandleNavigate();
      }}
    >
      <div className={classes.nameContainer}>
        <div className={classes.name}>
          <p>Create Project</p>
        </div>
      </div>
    </button>
  );
}

CreatePostButton.defaultProps = {
  id: null,
};
