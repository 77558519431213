import useTimeoutCleanup from "hooks/useTimeoutCleanup";
import useAppNavigation from "hooks/useAppNavigation";
import { useAppContext } from "./useAppContext";

export default function useEnterProjectPageTutorial() {
  // Get the required state from useAppContext
  const {
    setState,
    state: { intProjectTutorial, stepIndex, projectTutorial },
  } = useAppContext();

  // A function that has setTimeout but with its cleanup function built in
  const { cleanTimeout } = useTimeoutCleanup();

  // Get the navigation function from useNavMenu
  const { navTo } = useAppNavigation();

  // Handle navigation based on tour status and step index
  const HandleNavigate = () => {
    switch (true) {
      // If the project tour is active and the current step index is 0
      case projectTutorial.tourActive && stepIndex === 0:
        setState({ stepIndex: 1 }); // Set the step index to 1
        cleanTimeout(() => navTo("/projects/create"), 600);

        break;
      // If the internal project tour is active and the current step index is 0
      case intProjectTutorial.tourActive && stepIndex === 0:
        setState({ stepIndex: 1 }); // Set the step index to 1
        cleanTimeout(() => navTo("/projects/create"), 200);

        break;
      // If neither of the above conditions are met, simply navigate to the project create page
      default:
        navTo("/projects/create");
        break;
    }
  };

  return { HandleNavigate };
}
