import { Step } from "react-joyride";
import { useAppContext } from "./useAppContext";

export default function useStartTutorial() {
  const { setState } = useAppContext();

  // startTutorial function accepts two parameters:
  // tutorialSteps: an array of Step objects that defines the tour steps
  // tutorialName: a string that represents the name of the tutorial state
  // object to update (e.g. "mainTutorial", "projectTutorial", "settingsTutorial")
  const startTutorial = (tutorialSteps: Step[], tutorialName: string): void => {
    // Update the "steps" property of the app state with the tour steps
    setState({ steps: tutorialSteps });

    // Update the specified tutorial state object with the "run" and
    // "tourActive" properties set to true
    setState({
      [tutorialName]: {
        ...[tutorialName],
        run: true,
        tourActive: true,
      },
    });
  };

  // Return the startTutorial function as part of the hook's return object
  return { startTutorial };
}
