/* eslint-disable jsx-a11y/anchor-is-valid */
import Fade from "components/Elements/Fade/Fade";
import React, { useEffect } from "react";
// import { Toggle } from "rsuite";
import { getUser } from "store/slices/userSessionSlice";
import { useAppSelector } from "store/hooks";
import styled from "styled-components";
import useAppNavigation from "hooks/useAppNavigation";
import MobileSearch from "../MobileSearch/MobileSearch";

interface IProps {
  hide: () => void;
}

export interface IMobileNavLink {
  label: string;
  action: () => any;
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: var(--color-white);
  overflow: auto;
`;

const MobileMenu = styled.div`
  width: 100vw;
  height: 100vh;
  padding-top: 65px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const List = styled.ul`
  list-style: none;
  padding: 80px 0px;
  margin: 0;
`;

const ListItem = styled.li`
  text-align: center;
  margin-bottom: 15px;
`;

const Link = styled.button`
  color: var(--color-black);
  background-color: transparent;
  text-decoration: none;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  padding: 12px 12px;
  text-transform: capitalize;
  &:hover {
    cursor: pointer;
  }
`;

// const Actions = styled.div`
//   position: absolute;
//   float: right;
//   top: 90px;
//   right: 30px;
// `;

function MobileNavMenu(props: IProps) {
  const { hide } = props;

  // get nav menu urls

  const { navigateToProfile, navigateToProfileSettings, onLogout } =
    useAppNavigation();
  /**
   * Prevents scrolling when component is mounted
   */
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      // prevent scrolling
      const body = document.querySelector("body");
      if (body) {
        body.style.overflow = "hidden";
      }
    }

    return () => {
      mounted = false;

      // re-enable scrolling
      const body = document.querySelector("body");
      if (body) {
        body.style.removeProperty("overflow");
      }
    };
  }, []);

  // get store dispatcher
  // const dispatch = useAppDispatch();

  // Obtain the user info from the store
  const user = useAppSelector(getUser);

  // get current theme
  // const theme = useAppSelector(getColorTheme);

  // Dropdown menu items
  const navigationLinks: IMobileNavLink[] = [
    {
      label: "My Profile",
      action: () => navigateToProfile(user?._id || ""),
    },
    {
      label: "Settings",
      action: () => navigateToProfileSettings(),
    },

    {
      label: "Sign Out",
      action: () => onLogout(),
    },
  ];
  // const switchTheme = () => {
  //   const newTheme: ColorTheme = theme === "light" ? "dark" : "light";
  //   // call action to update theme
  //   dispatch(setTheme(newTheme));
  // };

  return (
    <Container>
      <Fade duration={150} delay={10}>
        <MobileMenu>
          <List>
            <ListItem>
              <MobileSearch hide={hide} />
            </ListItem>
            {navigationLinks.map((m) => (
              <ListItem key={m.label}>
                <Link
                  type="button"
                  onClick={() => {
                    m.action();
                    hide();
                  }}
                >
                  {m.label}
                </Link>
              </ListItem>
            ))}
            {/* <Actions>
              <Toggle
                checked={theme === "light"}
                arial-label="Switch"
                onChange={switchTheme}
                checkedChildren="light"
                unCheckedChildren="dark"
              />
            </Actions> */}
          </List>
        </MobileMenu>
      </Fade>
    </Container>
  );
}

export default MobileNavMenu;
