import { AxiosResponse } from "axios";
import { AuthError, getAuth, signOut } from "firebase/auth";
import { IServerErrorResponse } from "../types/server.interface";
import { openErrorToaster } from "./toast.service";
import { dispatchLogout } from "../store/dispatcher";

export const ERROR_TOAST_TIMER = 10000;

/**
 * Clears user session and logs user out
 * @param message
 * @param logout
 */
async function handleForcedLogout(message: string) {
  try {
    // get user session
    const auth = getAuth();
    // sign out user
    await signOut(auth);
    // clear user session
    dispatchLogout();
    // show error alert
    openErrorToaster(message, ERROR_TOAST_TIMER);
  } catch (e) {
    openErrorToaster(
      "An error occurred. Please refresh the page and try again.",
      ERROR_TOAST_TIMER
    );
  }
}

function axiosServerErrorHandler(
  response: AxiosResponse<IServerErrorResponse>
) {
  if (response.status === 403) {
    handleForcedLogout(response.data.message);
  } else if (response.status === 404) {
    // show error alert
    openErrorToaster(
      "Server Error. Please refresh the page and try again.",
      ERROR_TOAST_TIMER
    );
  } else {
    // show error alert
    openErrorToaster(response.data.message, ERROR_TOAST_TIMER);
  }
}

function defaultErrorHandler() {
  // show default error alert
  openErrorToaster(
    "Server Error. Please refresh the page and try again.",
    ERROR_TOAST_TIMER
  );
}

function firebaseAuthErrorHandler(error: AuthError) {
  const authError = error as AuthError;
  const errorCode = authError.code;

  switch (errorCode) {
    case "auth/user-not-found":
      openErrorToaster(
        "An account with that email does not exist",
        ERROR_TOAST_TIMER
      );
      break;
    case "auth/wrong-password":
      openErrorToaster(
        "Invalid Email/Password combination.",
        ERROR_TOAST_TIMER
      );
      break;
    case "auth/email-already-in-use":
      openErrorToaster(
        "An account with that email already exists.",
        ERROR_TOAST_TIMER
      );
      break;

    case "auth/weak-password":
      openErrorToaster(
        "Password must be at least characters long.",
        ERROR_TOAST_TIMER
      );
      break;

    case "auth/user-token-expired":
      openErrorToaster(
        "Password must be at least characters long.",
        ERROR_TOAST_TIMER
      );
      dispatchLogout(); // clear user session data
      break;

    case "auth/invalid-user-token":
      openErrorToaster(
        "Password must be at least characters long.",
        ERROR_TOAST_TIMER
      );
      dispatchLogout(); // clear user session data
      break;

    default:
      defaultErrorHandler();
      break;
  }
}

/**
 * This method handles server errors. An danger alert is
 * displayed with relevant info
 * @param error
 * @param logout
 */
export const serverErrorHandler = (error: any) => {
  // check if there is a axios response obj
  if (error.response) {
    axiosServerErrorHandler(error.response);
  } else if (error.message && error.code) {
    firebaseAuthErrorHandler(error);
  } else if (error.errorMessage) {
    openErrorToaster(error.errorMessage, ERROR_TOAST_TIMER, error.header);
  } else {
    defaultErrorHandler();
  }
};
