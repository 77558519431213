import { makeGetRequest } from "../../../config/axios.config";
import { GetNotificationsPayload } from "../types/index";

/**
 */
export default async function getNotifications(
  page: number,
  limit: number
): Promise<GetNotificationsPayload> {
  const url = `hustlencode/notifications?page=${page}&limit=${limit}`;
  const response = await makeGetRequest(url);
  return response.data.payload;
}
