import { AnyAction, EnhancedStore } from "@reduxjs/toolkit";
import { ThunkMiddleware } from "redux-thunk";
import { IUserSession } from "types/user.interface";
import { Notification } from "features/notifications";
import {
  IUserNotificationState,
  setNotifications,
  ISetUserNotificationsPayload,
  appendSocketNotification,
  markNotificationAsRead,
  denyTeamNotification,
  acceptTeamNotification,
  markAllAsRead,
  hideNotificationAlert,
  setNotificationAlert,
} from "./slices/userNotifications";
import {
  IUserSessionState,
  setIsDragging,
  setIsNotDragging,
  setTheme,
  setUser,
  setClearPageRedirect,
  setPageRedirectUrl,
} from "./slices/userSessionSlice";
import {
  IUserEditorState,
  expandHorizontalSplitWindow,
  finishExpandSplitWindow,
  updateEditorFontSize,
  updateHorizontalSplitWindowSizes,
  updateVerticalSplitWindowSizes,
} from "./slices/userEditorSlice";
import {
  ISetProfileFeedPayload,
  ISetTeamFeedPayload,
  ISetUserFeedPayload,
  clearFeed,
  setFeed,
  setProfileFeed,
  setTeamFeed,
} from "./slices/userFeedSlice";

let store: EnhancedStore<
  {
    userSession: IUserSessionState;
    userNotifications: IUserNotificationState;
    userEditor: IUserEditorState;
  },
  AnyAction,
  [
    ThunkMiddleware<
      {
        userSession: IUserSessionState;
        userNotifications: IUserNotificationState;
        userEditor: IUserEditorState;
      },
      AnyAction,
      undefined
    >
  ]
>;

export const injectStore = (
  _store: EnhancedStore<
    {
      userSession: IUserSessionState;
      userNotifications: IUserNotificationState;
      userEditor: IUserEditorState;
    },
    AnyAction,
    [
      ThunkMiddleware<
        {
          userSession: IUserSessionState;
          userNotifications: IUserNotificationState;
          userEditor: IUserEditorState;
        },
        AnyAction,
        undefined
      >
    ]
  >
) => {
  store = _store;
};

/**
 * Clears redux store of user session data
 */
export const dispatchLogout = () => {
  store.dispatch({ type: "LOGOUT" });
};

/**
 * Sets user data in redux store
 * @param user
 */
export const dispatchSetUser = (user: IUserSession) => {
  store.dispatch(setUser(user));
};

/**
 * Hides the notification icon
 */
export const dispatchHideUserNotificationAlert = () => {
  store.dispatch(hideNotificationAlert());
};

/**
 * Marks all notifications as read
 */
export const dispatchMarkAllAsRead = () => {
  store.dispatch(markAllAsRead());
};

/**
 * Sets user data in redux store
 * @param user
 */
export const dispatchSetUserNotifications = (
  payload: ISetUserNotificationsPayload
) => {
  store.dispatch(setNotifications(payload));
};

/**
 * Appends notification data that comes in via web socket to app store
 * @param payload
 */
export const dispatchAppendSocketNotification = (payload: Notification) => {
  store.dispatch(appendSocketNotification(payload));
};

/**
 * Marks a notification as read
 * @param id
 */
export const dispatchMarkNotificationAsRead = (id: string) => {
  store.dispatch(markNotificationAsRead(id));
};

/**
 * Marks the invitation as accepted for
 * the designated notification
 * @param id Main notification id
 */
export const dispatchAcceptNotification = (id: string) => {
  store.dispatch(acceptTeamNotification(id));
};

/**
 * Marks the invitation as denied for
 * the designated notification
 * @param id Main notification id
 */
export const dispatchDenyNotification = (id: string) => {
  store.dispatch(denyTeamNotification(id));
};

/**
 * Updates user feed history
 * @param id
 */
export const dispatchUpdateUserFeedHistory = (payload: ISetUserFeedPayload) => {
  store.dispatch(setFeed(payload));
};

/**
 * Updates user feed history
 * @param id
 */
export const dispatchUpdateTeamFeedHistory = (payload: ISetTeamFeedPayload) => {
  store.dispatch(setTeamFeed(payload));
};

/**
 * Updates user feed history
 * @param id
 */
export const dispatchUpdateProfileFeedHistory = (
  payload: ISetProfileFeedPayload
) => {
  store.dispatch(setProfileFeed(payload));
};

/**
 * Updates windows sizes in store
 * @param html
 * @param css
 * @param js
 */
export const dispatchUpdateHorizontalSplitWindowSizes = (
  html: number,
  css: number,
  js: number
) => {
  store.dispatch(updateHorizontalSplitWindowSizes({ html, css, js }));
};

export const dispatchUpdateVerticalSplitWindowSizes = (
  top: number,
  bottom: number
) => {
  store.dispatch(updateVerticalSplitWindowSizes({ top, bottom }));
};

/**
 * Clears the users feed history
 */
export const dispatchClearUserFeedHistory = () => {
  store.dispatch(clearFeed());
};

// updates split window sizes so the selected editor is max width
export const dispatchExpandSplitWindow = (window: "html" | "css" | "js") => {
  store.dispatch(expandHorizontalSplitWindow(window));
};

// sets the split view expand property to false - indicated that the windows were updated
export const dispatchFinishExpandSplitWindow = () => {
  store.dispatch(finishExpandSplitWindow);
};

// updated project editor font size
export const dispatchUpdateEditorFontSize = (s: number) => {
  store.dispatch(updateEditorFontSize(s));
};

export const dispatchSetNotificationAlert = () => {
  store.dispatch(setNotificationAlert());
};

export const dispatchSetTheme = (theme: ColorTheme) => {
  store.dispatch(setTheme(theme));
};

export const dispatchSetIsDragging = () => {
  store.dispatch(setIsDragging());
};

export const dispatchSetIsNotDragging = () => {
  store.dispatch(setIsNotDragging());
};

export const dispatchSetPageRedirect = (url: string) => {
  store.dispatch(setPageRedirectUrl(url));
};

export const dispatchClearPageRedirect = () => {
  store.dispatch(setClearPageRedirect());
};
