import styled from "styled-components";

const ImageContainer = styled.div`
  height: 40px;
  flex: 0 0 40px;
  min-width: 40px;
  overflow: hidden;
  border-radius: 20px;
  margin-right: 12px;
  position: relative;
  border: solid thin black;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContainerRight = styled.div`
  position: relative;
  flex: 0 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const ContainerLeft = styled.div`
  position: relative;
  flex: 0 0 50px;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

const ContainerCenter = styled.div`
  position: relative;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;

  p {
    font-size: 14px;
    line-height: 18px;

    b {
      font-size: 14px;
    }

    a {
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      color: var(--color-blue);
    }
  }
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding-right: 16px;
`;

const Timestamp = styled.div`
  span {
    font-size: 12px;
    line-height: 12px;
    margin-top: -4px;
    color: var(--color-black);
  }
`;

const CommentBody = styled.p`
  flex: 1;
  border: solid thin var(--color-card-border);
  margin: 10px 0px 0px 0px;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 20px 12px 20px;
  border-bottom: solid thin var(--color-card-border);
  align-items: center;
  color: var(--color-black);
  background-color: var(--color-white);
  user-select: none;

  &:hover {
    color: var(--color-white);
    background-color: var(--color-black);

    ${Timestamp} {
      span {
        color: var(--color-white);
      }
    }

    // change border color on notification hover
    ${Timestamp} {
      border-color: var(--color-white);
    }

    .notification-menu {
      svg {
        color: var(--color-white);
      }
    }
  }
`;

const ContainerToaster = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 20px 12px 20px;
  border-bottom: solid thin var(--color-card-border);
  align-items: center;
  color: var(--color-black);
  background-color: var(--color-white);
  user-select: none;
`;
export const NotificationCard = {
  Container,
  ContainerLeft,
  ContainerRight,
  ContainerCenter,
  TitleContainer,
  BodyContainer,
  Timestamp,
  ImageContainer, // for profile image
  CommentBody, // for comment notification messages
};

export const NotificationCardToaster = {
  Container: ContainerToaster,
  ContainerLeft,
  ContainerRight,
  ContainerCenter,
  TitleContainer,
  BodyContainer,
  Timestamp,
  ImageContainer, // for profile image
  CommentBody, // for comment notification messages
};

export const RedDot = styled.div`
  background-color: var(--color-red);
  position: absolute;
  height: 10px;
  width: 10px;
  top: -3px;
  left: -3px;
  z-index: 2;
  border-radius: 50%;
  overflow: hidden;
`;
