import moment from "moment";
import "moment-timezone";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "seconds",
    ss: "%ss",
    m: "%d minute",
    mm: "%dm",
    h: "%d hour",
    hh: "%dh",
    d: "%d day",
    dd: "%dd",
    M: "%d month",
    MM: "%dM",
    y: "a year",
    yy: "%dY",
  },
});

/**
 * Returns formatted date in fromNow format if less than 1hr
 * Otherwise it returns the formatted date  in 'MM/DD/YY, h:mm a'
 */
export const momentFromNow = (date: Date) => moment(date).fromNow();

/**
 * Returns the provided date in detailed format
 * Sunday, Jan 1, 2021 at 7:30 PM
 * @param date
 * @returns
 */
export const momentDateDetailed = (date: Date) => {
  const part1 = moment(date).tz("America/Los_Angeles").format("MMMM D, YYYY");
  const part2 = moment(date).tz("America/Los_Angeles").format("LT");
  return `${part1} at ${part2}`;
};

/**
 * Returns date in MM/DD/YY format
 */
export const getDateFormatShort = (date: Date) =>
  moment(date).tz("America/Los_Angeles").format("MM/DD/YY");
