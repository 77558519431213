import { useEffect, useState } from "react";

/**
 * @param hasChanges
 */
export default function useScroll() {
  const [top, setTop] = useState(0);
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setIsScroll(true);
      setTop(window.scrollY);
    };

    const onScrollEnd = () => {
      setIsScroll(false);
    };

    window.addEventListener("scroll", onScroll);
    window.addEventListener("scrollend", onScrollEnd);

    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("scrollend", onScrollEnd);
    };
  }, []);

  return { top, isScroll };
}
