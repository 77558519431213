import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../store/hooks";
import { setPreviousPath } from "../store/slices/userSessionSlice";

/**
 * Listens for route changes and records the last page the user
 * was on
 * @returns
 */
export default function useNavigationHistory() {
  const [searchParam] = useSearchParams();

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    let url = `${pathname}?`;

    searchParam.forEach((value, key) => {
      url = `${url}${key}=${value}&`;
    });
    dispatch(setPreviousPath(url));
  }, [pathname]);

  return {};
}
