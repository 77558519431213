import React from "react";
import ProfileImage from "components/Elements/ProfileImage/ProfileImage";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import useTeamNotificationCard from "features/notifications/hooks/useTeamNotificationCard";
import useNotifications from "features/notifications/hooks/useNotifications";
import styled from "styled-components";
// import useTeamNotificationCard from "features/notifications/hooks/useTeamNotificationCard";
import EllipsisMenu from "components/Elements/Menus/EllipsisMenu";
import MenuItem from "components/Elements/Menus/MenuItem";
import FromNowDate from "components/Elements/Date/FromNowDate";
import useAppNavigation from "hooks/useAppNavigation";
import { NotificationCard, RedDot } from "./styled/index";
import { Notification } from "../../types/index";

export interface ITeamInviteNotificationCardProps {
  notification: Notification;
  close: () => void;
}

const TeamNameContainer = styled.div`
  white-space: nowrap;

  p {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
`;
const DenyButton = styled.div`
  display: flex;
  border: solid thin red;
  padding-right: 10px;
  border-radius: 7px;
  align-items: center;
`;

const AcceptButton = styled.div`
  display: flex;
  border: solid thin green;
  padding-right: 10px;
  border-radius: 7px;
  align-items: center;
`;

/**
 * Renders the notification content for team invitations
 * @param props
 * @returns
 */
export default function TeamInviteNotificationCard(
  props: ITeamInviteNotificationCardProps
) {
  const { notification, close } = props;

  const { accept, deny } = useTeamNotificationCard(notification);

  // NOTE: During development, extrapolating the notification properties was causing an error, we might want to reformat this later

  const { teamInvitationNotification, creator } = notification;

  const { markAsRead, isLoading, isNotificationRead } = useNotifications();

  const { navigateToTeamPage } = useAppNavigation();

  // appends notification id before calling api
  const onMarkAsRead = () => markAsRead(notification._id);

  const menu = [];

  // add mark as read menu item
  if (!isNotificationRead(notification._id)) {
    menu.push(
      <MenuItem key="Mark as read" action={onMarkAsRead} label="Mark as read" />
    );
  }

  /**
   * Navigates to team page
   */
  const nav = () => {
    onMarkAsRead();
    navigateToTeamPage(teamInvitationNotification.team._id);
    close();
  };

  /** Denies the team invite */
  const denyInvite = () => {
    onMarkAsRead();
    deny();
    close();
  };

  /** Accepts the team invite and redirects the user to the team page */
  const acceptInvite = () => {
    accept().then(() => {
      // The code inside this block will execute only when accept() is successful
      onMarkAsRead();
      close();
      nav();
    });
  };

  return (
    <NotificationCard.Container>
      <NotificationCard.ContainerLeft>
        {!notification.read && <RedDot />}
        <NotificationCard.ImageContainer>
          <ProfileImage
            url={notification.creator.profileImg}
            gender={notification.creator.gender}
          />
        </NotificationCard.ImageContainer>
      </NotificationCard.ContainerLeft>

      <NotificationCard.ContainerCenter>
        <NotificationCard.TitleContainer>
          <p>
            <strong>
              <span>{creator.username}</span> Invited you to the team:
            </strong>
          </p>
        </NotificationCard.TitleContainer>

        <TeamNameContainer id="team-name-container">
          <p>{teamInvitationNotification.team.teamName}</p>
        </TeamNameContainer>

        <ButtonsContainer id="invite-buttons-container">
          <DenyButton id="deny-button" onClick={denyInvite}>
            <p style={{ marginLeft: 10, fontSize: "14px" }}>Deny:</p>
            <CloseOutlineIcon
              style={{
                fontSize: 15,
                marginLeft: 5,
                marginTop: 5,
                marginBottom: 5,
                color: "red",
              }}
            />
          </DenyButton>

          <AcceptButton id="accept-button" onClick={acceptInvite}>
            <p style={{ marginLeft: 10, fontSize: "14px" }}>Accept:</p>
            <CheckOutlineIcon
              style={{
                fontSize: 15,
                marginLeft: 5,
                marginTop: 5,
                marginBottom: 5,
                color: "green",
              }}
            />
          </AcceptButton>
        </ButtonsContainer>
      </NotificationCard.ContainerCenter>

      <NotificationCard.ContainerRight>
        {notification.read ? (
          <div style={{ height: 40, width: 40 }} />
        ) : (
          <EllipsisMenu
            className="notification-menu"
            menuOptions={menu}
            isLoading={isLoading}
            placement="bottomEnd"
            width={40}
            height={40}
            icon="horizontal"
          />
        )}

        <NotificationCard.Timestamp>
          <FromNowDate date={notification.createdOn} />
        </NotificationCard.Timestamp>
      </NotificationCard.ContainerRight>
    </NotificationCard.Container>
  );
}
