import React, { forwardRef, useState } from "react";
import { IUserSession } from "types/user.interface";
// import { useNavigate } from "react-router-dom";
// import { getFeedPage } from "util/navigation.util";
// import navigateToFeed from "hooks/useAppNavigation"
import { getFeedPage } from "util/navigation.util";
import useAppNavigation from "hooks/useAppNavigation";
import useIsMobile from "hooks/useIsMobile";
import styled from "styled-components";
import logoColorSrc from "assets/images/HNC_color.png";
import CreatePostButton from "features/projects/components/CreatePostButton/CreatePostButton";
import NotificationHubTrigger from "features/notifications/components/NotificationHubTrigger";
import NavBarProfileDropdown from "../NavBarProfileDropdown/NavBarProfileDropdown";
import NavbarSearch from "../NavBarSearch/NavBarSearch";
import MobileNavMenu from "../MobileNavMenu/MobileNavMenu";
import MobileMenuTrigger from "../MobileMenuTrigger";

const NavBarContainer = styled.div`
  display: flex;
  position: fixed !important;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 99;
  height: 65px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  display: flex;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin: 0 auto;
  z-index: 999;
  justify-content: space-between;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #d9d9d9;
  background-color: #ffffff;

  @media (min-width: 768px) {
    padding-left: 35px;
    padding-right: 35px;
  }

  @media (min-width: 992px) {
    padding-left: 45px;
    padding-right: 45px;
  }
`;

const NavBarBrand = styled.div`
  display: flex;
  order: 0;
  width: 105px;
  overflow: hiden;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 100%;
  }
`;

// const NavBarLeft = styled.div`
//   flex: 1;
//   position: relative;
//   order: 1;
//   display: flex;
//   margin-left: 15px;
//   margin-right: 15px;
// `;

const NavBarRight = styled.div`
  flex: 1;
  display: flex;
  order: 2;
  justify-content: flex-end;
`;

type NavBarProps = {
  user: IUserSession;
};

/**
 * Renders Navigation bar
 * - user profile is passed through props
 * - ref for search bar is also passed
 */
const NavBar = forwardRef((props: NavBarProps, ref: any) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { navigateToFeed } = useAppNavigation();

  // const navigate = useNavigate();
  // const feedPage = getFeedPage("user");

  const handleLogo = () => {
    const currentPath = window.location.pathname;
    const feedPath = getFeedPage("user");

    const feedPathFirstFiveChars = feedPath.substring(0, 5);
    if (currentPath === feedPathFirstFiveChars) {
      window.location.reload();
    } else {
      navigateToFeed("user");
    }
  };

  const { user } = props;

  const { isMobile } = useIsMobile();

  // render mobile navbar
  if (isMobile) {
    return (
      <>
        <NavBarContainer>
          <NavBarBrand onClick={handleLogo}>
            <img src={logoColorSrc} alt="hnc-logo" />
          </NavBarBrand>

          <NavBarRight>
            <MobileMenuTrigger
              color="black"
              isActive={isActive}
              setIsActive={setIsActive}
            />
          </NavBarRight>
        </NavBarContainer>

        {isActive ? <MobileNavMenu hide={() => setIsActive(false)} /> : null}
      </>
    );
  }

  // render default navbar
  return (
    <NavBarContainer>
      <NavBarBrand onClick={handleLogo}>
        <img src={logoColorSrc} alt="hnc-logo" />
      </NavBarBrand>

      {/* <NavBarLeft>
      </NavBarLeft> */}

      <NavBarRight>
        <NavbarSearch user={user} ref={ref} />
        <CreatePostButton />
        <NotificationHubTrigger />
        <NavBarProfileDropdown user={user} />
      </NavBarRight>
    </NavBarContainer>
  );
});

export default NavBar;
