import { makeGetRequest } from "config/axios.config";
import { IHncUserSearchResult } from "types/user.interface";
import { PaginateResult } from "types";

/**
 * Makes api request for user profiles based on a search query
 * @param search
 * @param count
 * @returns
 */
const getUserProfileSearchResults = async (
  search: string,
  // eslint-disable-next-line default-param-last
  count = 15,
  page: number
): Promise<PaginateResult<IHncUserSearchResult>> => {
  const url = `/hustlencode/search/profiles/?count=${count}&search=${search}&n=${page}`;
  const response = await makeGetRequest(url);
  // return user session data
  return response.data.payload;
};

export default getUserProfileSearchResults;
