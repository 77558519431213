import { useAppContext } from "features/onboarding/hooks/useAppContext";

export default function useCheckTutorialActive() {
  const {
    state: {
      mainTutorial,
      projectTutorial,
      intProjectTutorial,
      settingsTutorial,
    },
  } = useAppContext();

  const checkIfOneActive = () => {
    if (
      // Checks if One is Active. It doesn't matter which just if one is active
      !mainTutorial.tourActive &&
      !projectTutorial.tourActive &&
      !intProjectTutorial.tourActive &&
      !settingsTutorial.tourActive
    ) {
      // If all are not active. return false because not a single one is active
      return false;
    }
    // if one is active that means the above conditions returns false.

    // So we return true, because one tutorial is active
    return true;
  };
  return { checkIfOneActive };
}
