/**
 * Returns the users profile image
 * @returns
 */
export const getProfileImage = (
  img?: string | null,
  gender?: Gender
): string => {
  if (img === "assets/placeholders/male.jpg") {
    // return unspecified
    return "https://codespace-assets.s3.amazonaws.com/DefaultPFP.png";
  }

  if (img === "assets/placeholders/female.jpg") {
    // return unspecified
    return "https://codespace-assets.s3.amazonaws.com/DefaultPFP.png";
  }

  if (img === "assets/placeholders/unspecified.jpg") {
    // return unspecified
    return "https://codespace-assets.s3.amazonaws.com/DefaultPFP.png";
  }

  // return custom image
  if (img) return `${img}`;

  // if no img; return default profile image for males
  if (!img && gender === "male") {
    return "https://codespace-assets.s3.amazonaws.com/DefaultPFP.png";
  }

  // if no img; return default profile image for males
  if (!img && gender === "female") {
    return "https://codespace-assets.s3.amazonaws.com/DefaultPFP.png";
  }

  // if no img; return default profile image for unspecified
  return "https://codespace-assets.s3.amazonaws.com/DefaultPFP.png";
};

/**
 * Returns the users cover image
 * @param img
 * @returns
 */
export const getProfileCoverImage = (img: string | null): string => {
  if (img) {
    if (img === "assets/placeholders/cover-img.jpg") {
      // return default
      return "https://hustlencode.s3.us-west-1.amazonaws.com/pexels-kevin-ku-577585.jpg";
    }

    // return custom image
    if (img) return `${img}?${new Date().getTime()}`;
  }

  // return default
  return "https://hustlencode.s3.us-west-1.amazonaws.com/pexels-kevin-ku-577585.jpg";
};
