import React from "react";
import AppProvider from "providers/AppProvider";
import AppRoutes from "routing";
import StoreProvider from "./providers/StoreProvider";

// eslint-disable-next-line no-console
console.log("Version: 2.0.0");
function App() {
  return (
    <StoreProvider>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </StoreProvider>
  );
}

export default App;
